import * as React from "react";
import _ from 'lodash';
import { Tabs, Typography, Button, Badge, Card } from "antd";
import { Link } from "react-router-dom";
import { ActorType, ActorView, IncidentView, PagedIncidentQuery, PagedProjectQuery, PagedTaskQuery, PagedTicketQuery, PersonView, ProjectSortOption, ProjectStatus, ProjectView, SortDirection, TaskSortOption, TaskView, TicketView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { TagIds } from "../../Definitions/_definitions";
import client from "../../ApiClient/client";
import { Capabilities } from "../../Definitions/_capabilties";
import { Drawer } from "../Shared/Drawer";
import { ProjectWidget } from "../Projects/ProjectWidget";
import { TaskWidget } from "../Tasks";
import { ProjectCreateForm } from "../Projects";
import { TicketCreateForm, TicketWidget } from "../Tickets";
import { LoadingOutlined } from "@ant-design/icons";
import { IncidentWidget } from "../Incidents";
import IncidentCreateForm from "../Incidents/IncidentCreateForm";
import UserProfile from "../../Definitions/UserProfile";


const { TabPane } = Tabs;
const { Title } = Typography;

interface ActorProjectsAndTasksAndTicketsTabProps {
    actor: UserProfile | ActorView;
    dashboard?: boolean;
}

interface ActorProjectsAndTasksAndTicketsTabState {
    activeTab: "projects" | "tasks" | "tickets" | "incidents";
    projectQuery: Partial<PagedProjectQuery>;
    taskQuery: Partial<PagedTaskQuery>;
    ticketQuery: Partial<PagedTicketQuery>;
    incidentQuery: Partial<PagedIncidentQuery>;
    projectCount: number;
    taskCount: number;
    ticketCount: number;
    incidentCount: number;
    loading: boolean;
    showCreateDrawer: boolean;
}

export class ActorProjectsAndTasksAndTicketsTab extends React.Component<ActorProjectsAndTasksAndTicketsTabProps, ActorProjectsAndTasksAndTicketsTabState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "projects",
            projectQuery: {
                deleted: false,
                status: [ProjectStatus.InProgress],
                limit: 9999,
                sortBy: ProjectSortOption.LatestStart,
                sortDirection: SortDirection.Desc
            },
            taskQuery: {
                closed: false,
                sortBy: TaskSortOption.End,
                sortDirection: SortDirection.Asc,
                deleted: false,
                limit: 9999
            },
            ticketQuery: {
                deleted: false,
                closed: false,
                limit: 9999
            },
            incidentQuery: {
                deleted: false,
                closed: false,
                limit: 9999
            },
            projectCount: 0,
            taskCount: 0,
            ticketCount: 0,
            incidentCount: 0,
            loading: false,
            showCreateDrawer: false
        };
    }

    componentDidMount = async () => {
        const { actor } = this.props;
        if (!actor) return;

        const projectQuery = Object.assign({}, this.state.projectQuery);
        const taskQuery = Object.assign({}, this.state.taskQuery);
        const ticketQuery = Object.assign({}, this.state.ticketQuery);
        const incidentQuery = Object.assign({}, this.state.incidentQuery);

        const actorId = actor instanceof UserProfile ? actor.actorId : actor.id;

        //Project
        projectQuery.actorId = actorId;

        //Task
        taskQuery.actorId = actorId;

        //Ticket
        ticketQuery.customerIdOrAssigneeId = actorId;

        //Incident
        incidentQuery.myIncidents = actorId;
        

        this.setState({
            projectQuery,
            taskQuery,
            ticketQuery,
            incidentQuery
        });

        this.setState({ loading: true });

        await this.loadProjects(projectQuery);
        await this.loadTasks(taskQuery);
        await this.loadTickets(ticketQuery);
        await this.loadIncidents(incidentQuery);

        this.setState({ loading: false });
    }

    loadProjects = async (projectQuery: Partial<PagedProjectQuery>) => {
        const response = await client.projects.queryProjects(Object.assign({}, projectQuery, { limit: 0 })).catch(ex => { });
        if (!response) return;
        this.setState({ projectCount: response.data.count });
    }

    loadTasks = async (taskQuery: Partial<PagedTaskQuery>) => {
        const response = await client.tasks.queryTasks(Object.assign({}, taskQuery, { limit: 0 })).catch(ex => { });
        if (!response) return;
        this.setState({ taskCount: response.data.count });
    }

    loadTickets = async (ticketQuery: Partial<PagedTicketQuery>) => {
        const response = await client.tickets.queryTickets(Object.assign({}, ticketQuery, { limit: 0 })).catch(ex => { });
        if (!response) return;
        this.setState({ ticketCount: response.data.count });
    }

    loadIncidents = async (incidentQuery: Partial<PagedIncidentQuery>) => {
        const response = await client.incidents.queryIncidents(Object.assign({}, incidentQuery, { limit: 0 })).catch(ex => { });
        if (!response) return;
        this.setState({ incidentCount: response.data.count });
    }

    onTabChange = (activeTab) => {
        this.setState({ activeTab });
    }

    onProjectCreatedEvent = (collection: ProjectView[], project: ProjectView) => {

        if (project.customer && project.customer.id === (this.props.actor instanceof UserProfile ? this.props.actor.actorId : this.props.actor.id)) {
            const match = _.find(collection, a => { return a.id === project.id }) != null;
            if (!match) {
                collection.push(project);
                this.setState({ projectCount: this.state.projectCount + 1 });
            }
        }

        return collection;
    }

    onTaskCreatedEvent = async (collection: TaskView[], task: TaskView) => {
        if (task.owner && task.owner.id === (this.props.actor instanceof UserProfile ? this.props.actor.actorId : this.props.actor.id)) {
            const match = _.find(collection, a => { return a.id === task.id }) != null;
            if (!match) {
                collection.push(task);
                this.setState({ taskCount: this.state.taskCount + 1 });
            }
        }

        return collection;
    }

    onTicketCreatedEvent = (collection: TicketView[], ticket: TicketView) => {
        if (ticket.customer && ticket.customer.id === (this.props.actor instanceof UserProfile ? this.props.actor.actorId : this.props.actor.id)) {
            const match = _.find(collection, a => { return a.id === ticket.id }) != null;
            if (!match) {
                collection.push(ticket);
                this.setState({ ticketCount: this.state.ticketCount + 1 });
            }
        }

        return collection;
    }

    onIncidentCreatedEvent = (collection: IncidentView[], incident: IncidentView) => {
        if (incident.customers && incident.customers.find(x => x.id === (this.props.actor instanceof UserProfile ? this.props.actor.actorId : this.props.actor.id)) != null) {
            const match = _.find(collection, a => { return a.id === incident.id }) != null;
            if (!match) {
                collection.push(incident);
                this.setState({ incidentCount: this.state.incidentCount + 1 });
            }
        }

        return collection;
    }

    toggleCreateDrawer = async () => {
        this.setState({ showCreateDrawer: !this.state.showCreateDrawer });
    }

    render = () => {
        const { actor } = this.props;
        if (!actor) return <div className="noPermission"><h3>Project, tasks & tickets</h3>You don´t have the permission to see this content</div>;

        const isCustomer = _.find(actor.tags || [], t => { return t.id === TagIds.Invoiceable; }) != null;
        const isOrganization = actor.actorType === ActorType.Organization;

        const isCustomerOrOrganization = isCustomer || isOrganization;
        const isEmployee = actor == null ? false : actor.actorType === ActorType.Person ? (actor as PersonView).isEmployee  : false;

        const actorId = actor instanceof UserProfile ? actor.actorId : actor.id;

        const allProjectsLinkValue = isCustomerOrOrganization ? `customerId=${actorId}` : `actorId=${actorId}`;
        const allTasksLinkValue = isCustomerOrOrganization ? `customerId=${actorId}` : `ownerId=${actorId}&assigneeId=${actorId}`;
        const allTicketsLinkValue = isCustomerOrOrganization ? `customerId=${actorId}` : `assigneeId=${actorId}`;

        const allProjectsLink = <Link to={`/projects`}>See all projects</Link>;
        const allTasksLink = <Link to={`/tasks?${allTasksLinkValue}`}>See all tasks</Link>;
        const allTicketsLink = <Link to={`/tickets?${allTicketsLinkValue}`}>See all tickets</Link>;

        const gotSomeReadRights = this.context.user.hasCapability(Capabilities.ProjectsRoleBased) || ((isCustomerOrOrganization || isEmployee) && this.context.user.hasCapability(Capabilities.ProjectsRoleBased)) || this.context.user.hasCapability(Capabilities.TicketsReadRelated) || this.context.user.hasCapability(Capabilities.IncidentsReadRelated);

        let drawerComponent = null;
        let addButton = null;
        if (this.state.activeTab == "projects") {
            drawerComponent = <ProjectCreateForm onComplete={this.toggleCreateDrawer} filters={{ customerId: actorId }} />;
            addButton = this.context.user.hasCapability(Capabilities.ProjectsRoleBased) ? <Button size="small" onClick={this.toggleCreateDrawer}>Add project</Button> : null;
        }
        else if (this.state.activeTab == "tickets" && this.context.user.hasCapability(Capabilities.TicketsWriteRelated)) {
            drawerComponent = <TicketCreateForm onComplete={this.toggleCreateDrawer} filters={{ customerId: actorId }} />;
            addButton = this.context.user.hasCapability(Capabilities.ProjectsRoleBased) ? <Button size="small" onClick={this.toggleCreateDrawer}>Add ticket</Button> : null;
        }
        else if (this.state.activeTab == "incidents" && this.context.user.hasCapability(Capabilities.IncidentsWriteRelated)) {
            drawerComponent = <IncidentCreateForm onComplete={this.toggleCreateDrawer} incidentRequest={{ customers: [actorId] }} />;
            addButton = this.context.user.hasCapability(Capabilities.ProjectsRoleBased) ? <Button size="small" onClick={this.toggleCreateDrawer}>Add incident</Button> : null;
        }

        const operationsSlot = {
            right: !this.props.dashboard ? <div>{addButton}</div> : this.state.activeTab == "projects" ? allProjectsLink : this.state.activeTab == "tickets" ? allTicketsLink : this.state.activeTab == "tasks" ? allTasksLink : <div></div>
        };

        return (
            <>
                {gotSomeReadRights ? <Tabs defaultActiveKey={this.state.activeTab} onChange={this.onTabChange} className="actor-project-ticket-tab" tabBarExtraContent={operationsSlot}>
                    {this.context.user.hasCapability(Capabilities.ProjectsRoleBased) ? <TabPane key="projects" tab={<Title level={4} className="title">Open Projects <Badge className="actor-tab-badge" count={this.state.projectCount} showZero /></Title>}>
                        {this.state.loading ? <Card><LoadingOutlined /></Card> :
                            <ProjectWidget
                                title=""
                                query={this.state.projectQuery}
                                onItemCreatedEvent={this.onProjectCreatedEvent}
                                exclutions={["description", "customer", "roles", "projectLeader", "type", "start", "invoiced", "invoices"]}
                                loadAllOnLoadMore
                            />}
                    </TabPane> : null}

                    {(isCustomerOrOrganization || isEmployee) && this.context.user.hasCapability(Capabilities.ProjectsRoleBased) ?
                        <TabPane key="tasks" tab={<Title level={4} className="title">Open tasks <Badge className="actor-tab-badge" count={this.state.taskCount} showZero /></Title>}>
                            {this.state.loading ? <Card><LoadingOutlined /></Card> :
                                <TaskWidget
                                    title=""
                                    query={this.state.taskQuery}
                                    onItemCreatedEvent={this.onTaskCreatedEvent}
                                    exclutions={["end", "close", "owner", "assignee"]}
                                    loadAllOnLoadMore
                                />}
                        </TabPane> : null}

                    {this.context.user.hasCapability(Capabilities.TicketsReadRelated) ? <TabPane key="tickets" tab={<Title level={4} className="title">Open tickets <Badge className="actor-tab-badge" count={this.state.ticketCount} showZero /></Title>}>
                        {this.state.loading ? <Card><LoadingOutlined /></Card> :
                            <TicketWidget
                                title=""
                                query={this.state.ticketQuery}
                                onItemCreatedEvent={this.onTicketCreatedEvent}
                                exclutions={["status", "customer"]}
                                loadAllOnLoadMore
                            />}
                    </TabPane> : null}

                    {this.context.user.hasCapability(Capabilities.IncidentsReadRelated) && !this.props.dashboard ? <TabPane key="incidents" tab={<Title level={4} className="title">Open incidents <Badge className="actor-tab-badge" count={this.state.incidentCount} showZero /></Title>}>
                        {this.state.loading ? <Card><LoadingOutlined /></Card> :
                            <IncidentWidget
                                title=""
                                query={this.state.incidentQuery}
                                onItemCreatedEvent={this.onIncidentCreatedEvent}
                                loadAllOnLoadMore
                            />
                        }
                    </TabPane> : null}
                </Tabs> : <div className="noPermission"><h3>Project, tasks & tickets</h3>You don’t have permissions to see this content</div>}

                <Drawer
                    title={`Create ${this.state.activeTab}`}
                    onClose={this.toggleCreateDrawer}
                    open={this.state.showCreateDrawer}
                    component={drawerComponent}
                />
            </>
        );
    }
}

export default ActorProjectsAndTasksAndTicketsTab;