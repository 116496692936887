import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Tabs, Typography } from "antd";
import { ActivitySortOption, ActivityType, ActivityView, ActorType, ActorView, PagedActivityQuery, PersonView, SortDirection } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { CoreCollection } from "../../Definitions/collections";
import { TaskLink } from "../Tasks";
import { CategoryLink } from "../Categories";
import { Capabilities } from "../../Definitions/_capabilties";
import { ColumnChartCard } from "../Shared/Histogram";
import { ValueType } from "../../Models/ValueType";
import { ActivityWidget } from "../Activities";
import { ActorLink } from "./ActorLink";


const { TabPane } = Tabs;
const { Title } = Typography;

interface ActorRecentHoursTopContributorsTopCategoriesTabProps {
    actor: ActorView;
    aggregates: any;
}

interface ActorRecentHoursTopContributorsTopCategoriesTabState {
    activeTab: "hours" | "topContributors" | "topCategories";
}

export class ActorRecentHoursTopContributorsTopCategoriesTab extends React.Component<ActorRecentHoursTopContributorsTopCategoriesTabProps, ActorRecentHoursTopContributorsTopCategoriesTabState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);


        this.state = {
            activeTab: "hours"
        };
    }

    onTabChange = (activeTab) => {
        this.setState({ activeTab });
    }

    onActivityCreatedEvent = (collection: ActivityView[], activity: ActivityView) => {
        if (activity.relation && activity.relation.id == this.props.actor.id && activity.end != null) {
            const match = _.find(collection, a => { return a.id == activity.id }) != null;
            if (!match) {
                collection.push(activity);
                collection = _.sortBy(collection, "end").reverse();
            }
        }

        return collection;
    }

    render = () => {

        //Activities
        const recentHoursQuery: Partial<PagedActivityQuery> = {
            running: false,
            deleted: false,
            sortBy: ActivitySortOption.End,
            sortDirection: SortDirection.Desc,
            endBefore: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            limit: 5
        };

        if (this.props.actor.actorType == ActorType.Person && (this.props.actor as PersonView).isEmployee)
            recentHoursQuery.actorId = this.props.actor.id;
        else
            recentHoursQuery.relationId = this.props.actor.id;

        const activityColumns = [
            {
                title: 'Time',
                render: (activity: ActivityView) => {
                    let time = activity.start ? moment(activity.start).format('DD.MM.YYYY HH:mm') : "";

                    if (activity.end)
                        time = time + " - " + moment(activity.end).format('HH:mm');

                    return time;

                },
                key: 'start',
                width: 190
            },
            {
                title: 'Task/Category',
                render: (activity: ActivityView) => {
                    if (activity.type == ActivityType.Project)
                        return activity.task ? <TaskLink {...activity.task} /> : "";
                    else
                        return activity.category ? <CategoryLink {...activity.category} /> : "";
                },
                key: 'task',
                ellipsis: true
            },
            {
                title: 'Description',
                key: 'description',
                render: (activity: ActivityView) => <div className="vertical-limit">{activity.description}</div>
            }
        ];

        if (this.props.actor.actorType == ActorType.Organization) {
            activityColumns.splice(1, 0, {
                title: 'Employee',
                //@ts-ignore
                render: (activity: ActivityView) => activity.actor ? <ActorLink {...activity.actor} /> : "",
                key: "employee",
                ellipsis: true
            });
        }

        const isEmployee = this.props.actor.actorType == ActorType.Person ? (this.props.actor as PersonView).isEmployee : false;
        let contributorOrCustomerAgg = this.props.aggregates
            ? this.props.aggregates["actors:top:byDuration:asEmployee"] && Object.keys(this.props.aggregates["actors:top:byDuration:asEmployee"]).length > 0 ? this.props.aggregates["actors:top:byDuration:asEmployee"]
                : this.props.aggregates["actors:top:byDuration:asCustomer"] && Object.keys(this.props.aggregates["actors:top:byDuration:asCustomer"]).length > 0 ? this.props.aggregates["actors:top:byDuration:asCustomer"]
                    : [] : [];

        if (isEmployee)
            contributorOrCustomerAgg = this.props.aggregates ? this.props.aggregates["customers:top:byDuration"] : [];

        return (
            <Tabs defaultActiveKey={this.state.activeTab} onChange={this.onTabChange} className="actor-hours-contributors-categories-tab">
                {this.context.user.hasCapability(Capabilities.ActivitiesReadRelated) ? <TabPane key="hours" tab={<Title level={4} className="title">Recent hours</Title>}>
                    <ActivityWidget query={recentHoursQuery} onItemCreatedEvent={this.onActivityCreatedEvent} customColumns={activityColumns} />
                </TabPane> : null}

                {(isEmployee && this.context.user.hasCapability(Capabilities.OrganizationsRead)) || (!isEmployee && Capabilities.PeopleRead) ? <TabPane key="topContributors" tab={<Title level={4} className="title">{isEmployee ? 'Top customers' : 'Top contributors'}</Title>}>
                    <ColumnChartCard
                        title={<div style={{ fontSize: 14 }}>Last 12 months</div>}
                        xField="label"
                        yField="sum"
                        valueBy={ValueType.Duration}
                        height={200}
                        data={contributorOrCustomerAgg}
                    />
                </TabPane> : null}

                {this.context.user.hasCapability(Capabilities.ActivitiesReadTimeTrackingCategories) ? <TabPane key="topCategories" tab={<Title level={4} className="title">Top categories</Title>}>
                    <ColumnChartCard
                        title={<div style={{ fontSize: 14 }}>Last 12 months</div>}
                        xField="label"
                        yField="sum"
                        valueBy={ValueType.Duration}
                        height={200}
                        data={this.props.aggregates
                            ? this.props.aggregates["categories:top:byDuration"] && Object.keys(this.props.aggregates["categories:top:byDuration"]).length > 0 ? this.props.aggregates["categories:top:byDuration"]
                                : this.props.aggregates["categories:top:byDuration:asCustomer"] && Object.keys(this.props.aggregates["categories:top:byDuration:asCustomer"]).length > 0 ? this.props.aggregates["categories:top:byDuration:asCustomer"]
                                    : [] : []}
                    />
                </TabPane> : null}
            </Tabs>
        );
    }
}

export default ActorRecentHoursTopContributorsTopCategoriesTab;