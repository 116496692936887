import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Drawer } from "../../Modules/Shared/Drawer";
import ActivityCreateForm from "../../Modules/Activities/ActivityCreateForm";
import AppContext from "../../Definitions/AppContext";
import { CalendarResourceType } from "../../Models/CalendarModels";
import moment from "moment";
import { TicketCreateForm } from "../../Modules/Tickets";
import IncidentCreateForm from "../../Modules/Incidents/IncidentCreateForm";
import { PersonCreateForm } from "../../Modules/People";
import { OrganizationCreateForm } from "../../Modules/Organizations";
import { useNavigate } from "react-router-dom";
import { ActivitySortOption, ActivityView, SortDirection } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import ActivityUpdateForm from "../../Modules/Activities/ActivityUpdateForm";
import { Capabilities } from "../../Definitions/_capabilties";

const ActionHeaderDropdown = () => {
    const context = useContext(AppContext);
    const navigate = useNavigate();

    const [drawerVisible, setDrawerVisible] = useState<string | null>(null);
    const [runningActivity, setRunningActivity] = useState<ActivityView>();
    const [loadingRunningActivity, setLoadingRunningActivity] = useState<boolean>(false);

    const loadRunningActivities = useCallback(async () => {
        setLoadingRunningActivity(true);
        
        const response = await client.activities.queryActivities({
            actorId: context.user?.actorId,
            running: true,
            deleted: false,
            sortBy: ActivitySortOption.End,
            sortDirection: SortDirection.Desc,
            from: 0,
            limit: 5,
        });

        if (response) {
            const currentlyRunning = response.data.items.find(a => a.end == null);
            setRunningActivity(currentlyRunning);
        }

        setLoadingRunningActivity(false);
    }, [context.user?.actorId]);

    useEffect(() => {
        loadRunningActivities();
    }, [loadRunningActivities]);

    const onActivityEvent = useCallback((activity: ActivityView) => {
        if (runningActivity?.id == activity.id) {
            if (runningActivity.end == null && activity.end != null)
                setRunningActivity(null);
            else if (runningActivity.end == null && activity.end == null) //Replace the "fake activity" from activity form
                setRunningActivity(activity);
        }

        if (activity?.actor?.id == context.user?.actorId) {
            if (activity.end == null) {
                setRunningActivity(activity);
            }
        }
    }, [context.user?.actorId, runningActivity]);

    const onDeletedActivityEvent = useCallback((activity: ActivityView) => {
        if (activity.actor?.id === context.user?.actorId) {
            if (runningActivity && runningActivity.id === activity.id) {
                setRunningActivity(null);
            }
        }
    }, [context.user?.actorId, runningActivity]);

    useEffect(() => {
        context.events?.activities.onMany({
            "created": onActivityEvent,
            "updated": onActivityEvent,
            "deleted": onDeletedActivityEvent,
            "restored": onActivityEvent
        });

        return () => {
            context.events?.activities.offMany({
                "created": onActivityEvent,
                "updated": onActivityEvent,
                "deleted": onDeletedActivityEvent,
                "restored": onActivityEvent
            });
        }
    }, [context.events?.activities, onActivityEvent, onDeletedActivityEvent]);

    const drawerConfigs = [
        {
            key: 'trackHour',
            title: 'Track hours',
            component: (
                <ActivityCreateForm
                    activityMolds={[
                        {
                            resourceType: CalendarResourceType.Activity,
                            start: moment().startOf("hour").format("YYYY-MM-DDTHH:mm:ss"),
                            end: moment().startOf("hour").add(1, "hour").format("YYYY-MM-DDTHH:mm:ss"),
                        },
                    ]}
                    actorId={context.user?.actorId}
                    onClose={() => setDrawerVisible(null)}
                    onComplete={() => setDrawerVisible(null)}
                />
            ),
        },
        {
            key: 'startTimeClock',
            title: 'Start tracking',
            component: (
                <ActivityCreateForm
                    activityMolds={[
                        {
                            start: null,
                            end: null,
                        },
                    ]}
                    actorId={context.user?.actorId}
                    onClose={() => setDrawerVisible(null)}
                    onComplete={(response) => {
                        setRunningActivity(response.at(0));
                        setDrawerVisible(null);
                    }}
                    trackingForm
                />
            ),
        },
        {
            key: 'stopTimeClock',
            title: 'Stop tracking',
            component: (
                <ActivityUpdateForm
                    activity={runningActivity ?? null}
                    actorId={context.user?.actorId}
                    onClose={(response) => {
                        if (response && runningActivity?.id == response.id) setRunningActivity(null);
                        setDrawerVisible(null);
                    }}
                />
            ),
        },
        {
            key: 'createTicket',
            title: 'Create ticket',
            component: (
                <TicketCreateForm
                    onComplete={() => {
                        setDrawerVisible(null);
                    }}
                    onCancel={() => setDrawerVisible(null)}
                    filters={{
                        assigneeId: context.user?.actorId,
                    }}
                />
            ),
        },
        {
            key: 'registerIncident',
            title: 'Register incident',
            component: (
                <IncidentCreateForm
                    onComplete={() => {
                        setDrawerVisible(null);
                    }}
                    onCancel={() => setDrawerVisible(null)}
                />
            ),
        },
        {
            key: 'addPerson',
            title: 'Create person',
            component: (
                <PersonCreateForm
                    onCancel={() => setDrawerVisible(null)}
                    onComplete={() => {
                        setDrawerVisible(null);
                    }}
                />
            ),
        },
        {
            key: 'addOrganization',
            title: 'Create organization',
            component: (
                <OrganizationCreateForm
                    onCancel={() => setDrawerVisible(null)}
                    onComplete={() => {
                        setDrawerVisible(null);
                    }}
                />
            ),
        },
    ];

    const items: MenuProps['items'] = useMemo(() => {
        const labels = [
            { 
                key: '1', 
                label: 'Track hour', 
                onClick: () => setDrawerVisible('trackHour'), 
                capability: Capabilities.ActivitiesWriteActor,
            },
            { 
                key: '2', 
                label: loadingRunningActivity ? <>Start timeclock <LoadingOutlined /></> : (runningActivity != null ? 'Stop timeclock' : 'Start timeclock'), 
                onClick: () => setDrawerVisible(runningActivity != null ? 'stopTimeClock' : 'startTimeClock'),
                disabled: loadingRunningActivity,
                capability: Capabilities.ActivitiesWriteActor,
            },
            { 
                key: '3', 
                label: 'Create ticket', 
                onClick: () => setDrawerVisible('createTicket'), 
                capability: Capabilities.TicketsWriteRelated,
            },
            { 
                key: '4', 
                label: 'Register incident', 
                onClick: () => setDrawerVisible('registerIncident'), 
                capability: Capabilities.IncidentsWriteRelated,
            },
            { 
                key: '5', 
                label: 'Add person', 
                onClick: () => setDrawerVisible('addPerson'), 
                capability: Capabilities.PeopleWrite
            },
            { 
                key: '6', 
                label: 'Add organization', 
                onClick: () => setDrawerVisible('addOrganization'), 
                capability: Capabilities.OrganizationsWrite
            },
            { 
                key: '7', 
                label: 'Check revenue', 
                onClick: () => navigate('revenue'), 
                capability: Capabilities.ReportsReadRevenue 
            },
        ];

        return labels
            .filter(item => context.user?.capabilities?.includes(item.capability))
            .flatMap((item, index, array) => [
                item,
                ...(index < array.length - 1 ? [{ type: 'divider' as const }] : [])
            ]);
    }, [loadingRunningActivity, navigate, runningActivity, context.user?.capabilities]);

    return (
        <>
            <Dropdown
                menu={{ items }}
                placement="bottomRight"
                trigger={['click']}
                arrow
                destroyPopupOnHide
            >
                <PlusCircleOutlined />
            </Dropdown>
            {drawerConfigs.map(({ key, title, component }) => (
                <Drawer
                    key={key}
                    title={title}
                    onClose={() => setDrawerVisible(null)}
                    open={drawerVisible === key}
                    component={component}
                    destroyOnClose
                />
            ))}
        </>
    );
};

export default ActionHeaderDropdown;