import * as React from "react";
import _ from 'lodash';
import client from "../../ApiClient/client";
import AppContext from "../../Definitions/AppContext";
import { ActorType, CreateOrUpdateTicket, TaggableType, TicketView } from "../../ApiClient/swagger/data-contracts";
import { Capabilities } from "../../Definitions/_capabilties";
import { SelectorInput } from "../Shared/SelectorInput";
import { ActorSelector, CustomerSelector } from "../Actors";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { PersonSelector } from "../People";
import DateSelector from "../Shared/DateSelector";
import { TagSelector } from "../Tags";
import TicketStatusSelector from "./TicketStatusSelector";
import TicketSeveritySelector from "./TicketSeveritySelector";


interface EditTicketProps {
    ticket: TicketView,
    onComplete: (created?: TicketView) => void;
    onCancel?: () => void;
}

interface EditTicketState {
    loading: boolean;
    error: string;
}

class TicketEditForm extends React.Component<EditTicketProps, EditTicketState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
        }
    }

    onSubmit = async (request: CreateOrUpdateTicket) => {
        this.setState({ loading: true });

        try {
            const response = await client.tickets.updateTicket(this.props.ticket.id, request);
            if(response) this.props.onComplete();
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onDelete = async () => {
        if (this.props.ticket != null) {
            try {
                const response = await client.tickets.deleteTicket(this.props.ticket.id);
                if(response) this.props.onComplete();
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onRestore = async () => {
        if (this.props.ticket != null) {
            try {
                await client.tickets.restoreTicket(this.props.ticket.id);
                this.props.onComplete();
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    render = () => {
        if (!this.props.ticket) return <div>No ticket</div>;

        const actorTypes: ActorType[] = [];
        if (this.context.user.hasCapability(Capabilities.PeopleReadRelated))
            actorTypes.push(ActorType.Person);

        if (this.context.user.hasCapability(Capabilities.OrganizationsReadRelated))
            actorTypes.push(ActorType.Organization);

        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onCancel={this.props.onCancel}
                onRestore={this.onRestore}
                isDeleted={this.props.ticket.deleted}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={{
                    name: this.props.ticket.name,
                    description: this.props.ticket.description,
                    dueDate: this.props.ticket.dueDate,
                    customerId: this.props.ticket.customer?.id ?? null,
                    requesterId: this.props.ticket.requester?.id ?? null,
                    assigneeId: this.props.ticket.assignee ? this.props.ticket.assignee.id : null,
                    tagIds: _.map(this.props.ticket.tags, tag => {
                        if (tag.category?.deleted || tag?.deleted) return;
                        return tag.id; 
                    }),
                    status: this.props.ticket.status,
                    severity: this.props.ticket.severity,
                    otherParticipants: this.props.ticket.otherParticipants.map(p => p.id),
                }}
            >
                <TextInput
                    param="name"
                    placeholder="Title"
                    title="Title"
                    required
                />

                <TextAreaInput
                    param="description"
                    placeholder="Description"
                    title="Description"
                />

                <SelectorInput
                param="status"
                title= "Status"
                required
                selector={
                        <TicketStatusSelector
                            excludeAllOption
                        />
                    }
                />

                <SelectorInput
                param="severity"
                title= "Severity"
                selector={
                        <TicketSeveritySelector
                            excludeAllOption
                        />
                    }
                />

                {this.context.user.hasCapability(Capabilities.OrganizationsRead) && (
                    <SelectorInput
                        param="customerId"
                        title="Customer"
                        selector={
                            <CustomerSelector
                                types={actorTypes}
                                placeholder="Select customer..."
                            />
                        }
                    />
                )}

                <SelectorInput
                    param="requesterId"
                    title="Requester"
                    selector={
                        <ActorSelector
                            placeholder="Select requester..."
                        />
                    }
                />

                <SelectorInput
                    param="assigneeId"
                    title="Assignee"
                    selector={<PersonSelector filters={{ isEmployee: true }} placeholder="Select person" disabled={!this.context.user.hasCapability(Capabilities.TicketsWrite)} />}
                />

                <SelectorInput
                    param="dueDate"
                    title="Due date"
                    selector={<DateSelector />}
                />

                <SelectorInput
                    param="otherParticipants"
                    title="Other participants"
                    selector={<PersonSelector multiple placeholder="Select people" filters={{ isEmployee: true }} />}
                />

                <SelectorInput
                    param="tagIds"
                    title="Tags"
                    selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Ticket] }} />}
                />
            </BaseForm>
        );
    }
}

export default TicketEditForm;