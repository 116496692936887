import _ from 'lodash';
import moment from 'moment';
import { Suspense, useEffect, useMemo, useState } from 'react';
import AppContext from './Definitions/AppContext';
import Layout from 'antd/lib/layout/layout';
import AppContent from './Framework/AppContent';
import AppMenu from './Framework/AppMenu';
import { ErrorLayout } from './Pages/ErrorPage';
import ErrorBoundary from './Framework/ErrorBoundary';
import AppLayout from './Framework/AppLayout';
import { Button, ConfigProvider } from 'antd';
import { WebAuthenticationInfo } from './ApiClient/swagger/data-contracts';
import { LoadingLayout, LoadingPage } from './Pages/LoadingPage';
import Routes from './Framework/Routes';
import UserProfile from './Definitions/UserProfile';
import AppConfiguration from './Framework/AppConfig';
import { EventTransformer, IEventTransformer, ScopeStateData } from './ApiClient/Events/EventTransformer';
import { openNotification, closeNotification } from './Helpers/BasePageHelpers';
import eventClient, { EventClient } from './ApiClient/Events';
import client from './ApiClient/client';
import { AccessDeniedPageLayout } from './Pages/AccessDeniedPage';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import nbNO from 'antd/lib/locale/nb_NO';


moment.updateLocale('nb', {
    week: {
        dow: 1,
        doy: 1,
    },
});


let idleTimer: NodeJS.Timeout;
let eventSourceTimer: NodeJS.Timeout;

function App() {

    const [isIdle, setIsIdle] = useState(false);

    const [isMobile, setIsMobile] = useState(false);
    const [auth, setAuth] = useState<WebAuthenticationInfo>();
    const [error, setError] = useState<string>();
    const [AppConfig, setAppSettings] = useState<AppConfiguration>();
    const [events, setEventClient] = useState<EventClient>();
    const [breadcrumb, setBreadcrumb] = useState<React.ReactNode>();

    const [eventState, setEventState] = useState<Record<string, string>>();
    const [prevEventState, setPreEventState] = useState<Record<string, string>>();
    const [refreshEvent, setRefreshEvent] = useState<boolean>();
    const [byndleEnabled, setByndleEnabled] = useState<boolean>();

    //const [isLoadingAppSettings, setLoadingAppSettings] = useState(false);
    const [tempEventTransformer, setEventTransformer] = useState<IEventTransformer>();


    async function getAppSettings() {
        //setLoadingAppSettings(true);

        const AppConfig = new AppConfiguration();
        //TODO future?: Get stored themes, colors etc. and add them to antdConfigProvider
        setAppSettings(AppConfig);

        //setLoadingAppSettings(false);
    }

    async function getAuth() {
        const resp = await client.auth.getAuthenticationStatus().catch(exception => setError(exception.error));

        if (resp) {
            setAuth(resp.data);
        }
    }

    async function getByndleEnabled() {
        const resp = await client.organizations.getByndleActive().catch(() => { });

        if (resp) {
            setByndleEnabled(resp.data);
        }
    }

    function resetTimer() {
        if (isIdle)
            setIsIdle(false);

        clearTimeout(idleTimer);
        idleTimer = setTimeout(() => { setIsIdle(true) }, 60000)
    }

    useEffect(() => {
        if (AppConfig == null) getAppSettings();

        function handleResize() {
            const { innerWidth: width } = window;
            if (width <= AppConfig?.settings?.mobileWidth && !isMobile) {
                setIsMobile(true);
            } else if (width > AppConfig?.settings?.mobileWidth && isMobile) {
                setIsMobile(false);
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [AppConfig, AppConfig?.settings?.mobileWidth, isMobile]);

    useEffect(() => {
        if (auth != null && auth.isAuthenticated) {
            getScopes();
        }
    }, [auth?.isAuthenticated ?? false])


    useEffect(() => {
        const interval = setInterval(() => getAuth(), 3600000) //once an hour we check if you're still authenticated
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        const hasCapabilities = auth != null && auth.userInfo != null && auth.userInfo.capabilities != null && auth.userInfo.capabilities.length > 0;
        const locationIsAccessDenied = window.location.pathname === "/access-denied";

        if ((auth != null && !auth.isAuthenticated) && !locationIsAccessDenied) {
            window.location.replace("/login");
        }
        else if (auth != null && auth.isAuthenticated && auth.userInfo?.actorId !== null && !hasCapabilities && !locationIsAccessDenied) {
            window.location.reload();
        }

    }, [auth]);

    useEffect(() => {
        const idleEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
        idleEvents.forEach(function (name) {
            document.addEventListener(name, resetTimer, true);
        });

        getAuth();
        getByndleEnabled();
    }, [])

    useEffect(() => {
        const hasDCScope = eventState && _.find(Object.values(eventState ?? {}), state => state === 'disconnected') !== undefined;
        const hadPrevDCScope = prevEventState && _.find(Object.values(prevEventState ?? {}), state => state === 'disconnected') !== undefined;
        const hasAllScopes = eventState && Object.keys(eventState).length === 3;

        if (hasDCScope && !hadPrevDCScope && hasAllScopes) {
            if (eventSourceTimer)
                clearTimeout(eventSourceTimer);

            openNotification(
                "Connection failed - Service may be unavailable",
                <>
                    <p>Until connection is restored, some data may appear incorrect.</p>
                    <Loading3QuartersOutlined spin />
                </>,
                "info",
                null,
                "connection-lost",
                false
            );
        }

        if (!refreshEvent && hasDCScope) {
            if (!eventSourceTimer)
                eventSourceTimer = setTimeout(() => {
                    openNotification(
                        "Connection to server lost - trying to restore",
                        <>
                            <p>Until connection is restored, some data may appear incorrect.</p>
                            <Loading3QuartersOutlined spin />
                            {/*<p>We are trying to restore it, but while we do automatic updates and saving data may not work</p >
                        {_.map(eventState, (e, key) => {
                            return <span>{e === 'connected' ? <CheckCircleOutlined style={{ color: "green" }} /> : <CloseCircleOutlined style={{ color: "red" }} />} {capitalizeFirstLetter(key)}</span>;
                        })}
                        */}
                        </>,
                        "info",
                        null,
                        "connection-lost",
                        false
                    );

                }, 5000);
           
        }
        else if (refreshEvent || !hasDCScope) {
            closeNotification("connection-lost");
            if (eventSourceTimer)
                clearTimeout(eventSourceTimer);
        }

        if (!refreshEvent && hadPrevDCScope && !hasDCScope) {
            if (eventSourceTimer)
                clearTimeout(eventSourceTimer);
            else
                openNotification(
                    "Connection restored",
                    <>
                        <p>The connection to the server is restored</p>
                    </>,
                    "success",
                    null,
                    "connection-restored",
                    true
                );
        }

    }, [eventState])

    useEffect(() => {
        return () => {
            tempEventTransformer?.eventSources.forEach(es => es.close());
        }
    }, [tempEventTransformer?.eventSources])

    async function getScopes() {
        const response = await fetch("/sse");

        if (response && response.status === 200) {
            const scopes = await response.json() as Record<string, string>;

            const eventStream = new EventTransformer(scopes);

            //TODO: Use the changes here to display a warning about the connection when a connection went down, and remove it again when all connections are back up
            eventStream.subscribe('scope-state', (data) => {
                setEventState(prevState => {
                    const { scope, state } = data as ScopeStateData;
                    const update = { ...prevState, [scope]: state };
                    setPreEventState(prevState);
                    return update;
                });
            });

            eventStream.subscribe('refresh', () => {
                if (isIdle) {
                    window.location.reload();
                }
                else {
                    setRefreshEvent(true);

                    //TODO: Different message based on connection.any(disconnected)?
                    openNotification(
                        `Connection restored - Refresh required`,
                        <><p>The connection to the server is restored, but a refresh is required to get you up to date</p><Button type="default" onClick={() => window.location.reload()}>Click here to refresh</Button></>,
                        "warning",
                        () => window.location.reload(),
                        "event-error",
                        false
                    );
                }
            });

            eventStream.subscribe('closed', () => {
                eventSourceTimer = setTimeout(() => {
                    openNotification(
                        "Connection lost - trying to restore",
                        <>
                            <p>Until connection is restored, some data may appear incorrect.</p>
                            <Loading3QuartersOutlined spin />
                        </>,
                        "info",
                        null,
                        "connection-lost",
                        false
                    );

                }, 5000);
            });

            eventStream.subscribe('bad-authentication', async () => {
                const resp = await client.auth.getAuthenticationStatus().catch(exception => setError(exception.error));

                if (!resp || !resp.data || !resp.data.isAuthenticated)
                    window.location.href = ("/login");
            });

            setEventClient(eventClient(eventStream));

            tempEventTransformer?.eventSources.forEach(es => es.close());
            setEventTransformer(eventStream);
        }
    }

    const appContextValue = useMemo(() => ({
        user: new UserProfile(auth),
        isMobile,
        applicationId: auth?.applicationId,
        tenantId: auth?.tenantId,
        events,
        breadcrumb,
        byndleEnabled: byndleEnabled,
        setBreadcrumbItems: (breadcrumbItems) => setBreadcrumb(breadcrumbItems)
    }), [auth, isMobile, events, breadcrumb, byndleEnabled]);

    const suspenseRoutes = useMemo(() => (
        <Suspense fallback={<LoadingPage />}>
            <ErrorBoundary>
                <Routes appRoutes={AppConfig?.routes} />
            </ErrorBoundary>
        </Suspense>
    ), [AppConfig?.routes]);

    if (error) return <ErrorLayout status="500" />;
    if (window.location.pathname === "/access-denied") return <AccessDeniedPageLayout />;
    if (!AppConfig || !events /*|| !auth || !auth.isAuthenticated */) return <LoadingLayout />;

    return (
        <AppContext.Provider value={appContextValue}>
            <ConfigProvider {...AppConfig.antdOptions} locale={nbNO}>
                <AppLayout menus={AppConfig.menus}><Layout className="app-section drawer-render-container">{isMobile ? null : <AppMenu menus={AppConfig.menus} />}<AppContent>{suspenseRoutes}</AppContent></Layout></AppLayout>
            </ConfigProvider>
        </AppContext.Provider>
    );
}

export default App;