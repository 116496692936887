/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateEmployee,
  EmployeeSortOption,
  EmployeeView,
  EmployeeViewEntityViewModel,
  EmployeeViewPagedEmployeeQueryCollectionViewModel,
  HourBankView,
  IllnessChildView,
  SortDirection,
  UpdateEmployee,
  UpdateEmployeeHourBankCommand,
  UpdateEmployeeIllnessChildCommand,
  UpdateEmployeeVacationDaysCommand,
  VacationView,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Employees<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeById
   * @request GET:/api/employees/{id}
   * @secure
   */
  getEmployeeById = (id: string, params: RequestParams = {}) =>
    this.http.request<EmployeeViewEntityViewModel, any>({
      path: `/api/employees/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name UpdateEmployee
   * @request PUT:/api/employees/{id}
   * @secure
   */
  updateEmployee = (id: string, data: UpdateEmployee, params: RequestParams = {}) =>
    this.http.request<EmployeeView, any>({
      path: `/api/employees/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name DeleteEmployee
   * @request DELETE:/api/employees/{id}
   * @secure
   */
  deleteEmployee = (id: string, params: RequestParams = {}) =>
    this.http.request<EmployeeView, any>({
      path: `/api/employees/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name CreateEmployee
   * @request POST:/api/employees
   * @secure
   */
  createEmployee = (data: CreateEmployee, params: RequestParams = {}) =>
    this.http.request<EmployeeView, any>({
      path: `/api/employees`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name QueryEmployees
   * @request GET:/api/employees
   * @secure
   */
  queryEmployees = (
    query?: {
      sortBy?: EmployeeSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      /** @format int32 */
      birthYear?: number;
      /** @format int32 */
      birthMonth?: number;
      /** @format int32 */
      birthDay?: number;
      tagIds?: string[];
      isSupplier?: boolean;
      isCustomer?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<EmployeeViewPagedEmployeeQueryCollectionViewModel, any>({
      path: `/api/employees`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name RestoreEmployee
   * @request POST:/api/employees/{id}/restore
   * @secure
   */
  restoreEmployee = (id: string, params: RequestParams = {}) =>
    this.http.request<EmployeeView, any>({
      path: `/api/employees/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name UpdateEmployeeAttributes
   * @request POST:/api/employees/{id}/attributes
   * @secure
   */
  updateEmployeeAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<EmployeeView, any>({
      path: `/api/employees/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeVacation
   * @request GET:/api/employees/_all/vacations/{year}
   * @secure
   */
  getEmployeeVacation = (year: number, params: RequestParams = {}) =>
    this.http.request<VacationView[], any>({
      path: `/api/employees/_all/vacations/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeVacationById
   * @request GET:/api/employees/{id}/vacation/{year}
   * @secure
   */
  getEmployeeVacationById = (id: string, year: number, params: RequestParams = {}) =>
    this.http.request<VacationView, any>({
      path: `/api/employees/${id}/vacation/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name UpdateEmployeeVacation
   * @request PUT:/api/employees/vacation
   * @secure
   */
  updateEmployeeVacation = (data: UpdateEmployeeVacationDaysCommand, params: RequestParams = {}) =>
    this.http.request<VacationView[], any>({
      path: `/api/employees/vacation`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeIllnessChild
   * @request GET:/api/employees/_all/childillnesses/{year}
   * @secure
   */
  getEmployeeIllnessChild = (year: number, params: RequestParams = {}) =>
    this.http.request<IllnessChildView[], any>({
      path: `/api/employees/_all/childillnesses/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeIllnessChildById
   * @request GET:/api/employees/{id}/childillness/{year}
   * @secure
   */
  getEmployeeIllnessChildById = (id: string, year: number, params: RequestParams = {}) =>
    this.http.request<IllnessChildView, any>({
      path: `/api/employees/${id}/childillness/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name UpdateEmployeeIllnessChild
   * @request PUT:/api/employees/childillness
   * @secure
   */
  updateEmployeeIllnessChild = (data: UpdateEmployeeIllnessChildCommand, params: RequestParams = {}) =>
    this.http.request<IllnessChildView[], any>({
      path: `/api/employees/childillness`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeHourBank
   * @request GET:/api/employees/_all/hourBanks/{year}
   * @secure
   */
  getEmployeeHourBank = (year: number, params: RequestParams = {}) =>
    this.http.request<HourBankView[], any>({
      path: `/api/employees/_all/hourBanks/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeHourBankById
   * @request GET:/api/employees/{id}/hourBank/{year}
   * @secure
   */
  getEmployeeHourBankById = (id: string, year: number, params: RequestParams = {}) =>
    this.http.request<HourBankView, any>({
      path: `/api/employees/${id}/hourBank/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name UpdateEmployeeHourBank
   * @request PUT:/api/employees/hourBank
   * @secure
   */
  updateEmployeeHourBank = (data: UpdateEmployeeHourBankCommand, params: RequestParams = {}) =>
    this.http.request<HourBankView[], any>({
      path: `/api/employees/hourBank`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
