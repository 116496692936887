/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateFileOrFolder,
  CreateSalaryRequest,
  EmployeeSalaryViewViewModel,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  PreviewType,
  RevertFileToVersion,
  SalaryCalculationRequest,
  SalaryConfirmTransferRequest,
  SalarySortOption,
  SalaryTransferRequest,
  SalaryView,
  SalaryViewEntityViewModel,
  SalaryViewPagedQuerySalaryCollectionViewModel,
  SortDirection,
  UpdateFileOrFolder,
  UpdateSalaryRequest,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Salary<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Salary
   * @name GetSalaryById
   * @request GET:/api/salary/{id}
   * @secure
   */
  getSalaryById = (id: string, params: RequestParams = {}) =>
    this.http.request<SalaryViewEntityViewModel, any>({
      path: `/api/salary/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Salary
   * @name UpdateSalary
   * @request PUT:/api/salary/{id}
   * @secure
   */
  updateSalary = (id: string, data: UpdateSalaryRequest, params: RequestParams = {}) =>
    this.http.request<SalaryView, any>({
      path: `/api/salary/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Salary
   * @name QuerySalaries
   * @request GET:/api/salary
   * @secure
   */
  querySalaries = (
    query?: {
      sortBy?: SalarySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      requireCalculation?: boolean;
      availableForTransfer?: boolean;
      /** @format date-time */
      startDate?: string;
      /** @format date-time */
      endDate?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SalaryViewPagedQuerySalaryCollectionViewModel, any>({
      path: `/api/salary`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Salary
   * @name CreateSalary
   * @request POST:/api/salary
   * @secure
   */
  createSalary = (data: CreateSalaryRequest, params: RequestParams = {}) =>
    this.http.request<SalaryView, any>({
      path: `/api/salary`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Salary
   * @name GetSalaryForEmployee
   * @request GET:/api/salary/employees/{employeeId}
   * @secure
   */
  getSalaryForEmployee = (employeeId: string, params: RequestParams = {}) =>
    this.http.request<EmployeeSalaryViewViewModel, any>({
      path: `/api/salary/employees/${employeeId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Salary
   * @name CalculateSalary
   * @request PUT:/api/salary/{id}/current
   * @secure
   */
  calculateSalary = (id: string, data: SalaryCalculationRequest, params: RequestParams = {}) =>
    this.http.request<SalaryView, any>({
      path: `/api/salary/${id}/current`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Salary
   * @name TransferSalary
   * @request PUT:/api/salary/{id}/transfer
   * @secure
   */
  transferSalary = (id: string, data: SalaryTransferRequest, params: RequestParams = {}) =>
    this.http.request<SalaryView, any>({
      path: `/api/salary/${id}/transfer`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Salary
   * @name ConfirmSalaryTransfer
   * @request PUT:/api/salary/{id}/confirm
   * @secure
   */
  confirmSalaryTransfer = (id: string, data: SalaryConfirmTransferRequest, params: RequestParams = {}) =>
    this.http.request<SalaryView, any>({
      path: `/api/salary/${id}/confirm`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name GetSalaryFileById
   * @request GET:/api/salary/{entityId}/files/{fileId}
   * @secure
   */
  getSalaryFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/salary/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name UpdateSalaryFile
   * @request PUT:/api/salary/{entityId}/files/{fileId}
   * @secure
   */
  updateSalaryFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/salary/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name DeleteSalaryFile
   * @request DELETE:/api/salary/{entityId}/files/{fileId}
   * @secure
   */
  deleteSalaryFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/salary/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name DownloadSalaryFile
   * @request GET:/api/salary/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadSalaryFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/salary/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name PreviewSalaryFile
   * @request GET:/api/salary/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewSalaryFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/salary/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name CreateSalaryFile
   * @request POST:/api/salary/{entityId}/files
   * @secure
   */
  createSalaryFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/salary/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name QuerySalaryFiles
   * @request GET:/api/salary/{entityId}/files
   * @secure
   */
  querySalaryFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/salary/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name RestoreSalaryFile
   * @request POST:/api/salary/{entityId}/files/{fileId}/restore
   * @secure
   */
  restoreSalaryFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/salary/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name MoveSalaryFile
   * @request POST:/api/salary/{entityId}/files/{fileId}/move
   * @secure
   */
  moveSalaryFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/salary/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name RevertSalaryFile
   * @request POST:/api/salary/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertSalaryFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/salary/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name GetSalaryFolderById
   * @request GET:/api/salary/{entityId}/folder/{folderId}
   * @secure
   */
  getSalaryFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/salary/${entityId}/folder/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name CreateSalaryFolder
   * @request POST:/api/salary/{entityId}/folders
   * @secure
   */
  createSalaryFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/salary/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name QuerySalaryFolders
   * @request GET:/api/salary/{entityId}/folders
   * @secure
   */
  querySalaryFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/salary/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name UpdateSalaryFolder
   * @request PUT:/api/salary/{entityId}/folders/{folderId}
   * @secure
   */
  updateSalaryFolder = (entityId: string, folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/salary/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name DeleteSalaryFolder
   * @request DELETE:/api/salary/{entityId}/folders/{folderId}
   * @secure
   */
  deleteSalaryFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/salary/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name RestoreSalaryFolder
   * @request POST:/api/salary/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restoreSalaryFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/salary/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SalaryFile
   * @name MoveSalaryFolder
   * @request POST:/api/salary/{entityId}/{folderId}/move
   * @secure
   */
  moveSalaryFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/salary/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
