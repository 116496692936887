export enum Capabilities {
    ActivitiesConfirm = 'activities:confirm',
    ActivitiesConfirmActor = 'activities:confirm:actor',
    ActivitiesConfirmRelated = 'activities:confirm:related',
    ActivitiesRead = 'activities:read',
    ActivitiesReadActor = 'activities:read:actor',
    ActivitiesReadRelated = 'activities:read:related',
    ActivitiesReadTimeTrackingCategories = 'activities:timetracking:read',
    ActivitiesTransfer = 'activities:transfer',
    ActivitiesWrite = 'activities:write',
    ActivitiesWriteActor = 'activities:write:actor',
    ActivitiesWriteTimeTrackingCategories = 'activities:timetracking:write',
    ActivitiesAdministrateAbsence = 'activities:administrate-absence',
    ApplicationFilesRead = 'files:application:read',
    ApplicationFilesWrite = 'files:application:write',
    ApplicationNotesRead = 'notes:application:read',
    ApplicationNotesWrite = 'notes:application:write',
    ApplicationsRead = 'applications:read',
    ApplicationsWrite = 'applications:write',
    CallNotesRead = 'notes:call:read',
    CallNotesWrite = 'notes:call:write',
    CallsRead = 'calls:read',
    CallsReadActor = 'calls:read:actor',
    CallsReadRelated = 'calls:read:related',
    CallsWrite = 'calls:write',
    CallsWriteActor = 'calls:write:actor',
    CallsWriteRelated = 'calls:write:related',
    CustomersRead = 'customers:read',
    CustomersWrite = '"customers:write',
    ChannelsWrite = 'channels:write',
    ContactPointsRead = 'contactpoints:read',
    ContactPointsWrite = 'contactpoints:write',
    CustomerCategoriesWrite = 'customers:categories:write',
    CustomerCategoriesRead = 'customers:categories:read',
    DashboardsRead = 'dashboards:read',
    DashboardsWrite = 'dashboards:write',
    DashboardsWritePersonal = 'dashboards:write:personal',
    EmployeesRead = 'employees:read',
    EmployeesWrite = 'employees:write',
    EmployeesAdministrateSalaryBasis = 'employees:salary:administrate',
    MeetingsRead = 'meetings:read',
    MeetingsReadPersonal = 'meetings:read:personal',
    MeetingsWrite = 'meetings:write',
    MeetingsWritePersonal = 'meetings:write:personal',
    ExternalLinksRead = 'externallinks:read',
    ExternalLinksWrite = 'externallinks:write',
    ExternalLoginsRead = 'logins:read',
    ExternalLoginsWrite = 'logins:write',
    FilesRead = 'files:read',
    FilesWrite = 'files:write',
    FilesReadApplications = 'files:read:application',
    //FilesWriteApplications = 'files:write:application',
    IncidentFilesRead = 'files:incident:read',
    IncidentFilesWrite = 'files:incident:write',
    IncidentCommentsRead = 'comments:incident:read',
    IncidentCommentsWrite = 'comments:incident:write',
    IncidentsCategoriesRead = 'incidents:categories:read',
    IncidentsCategoriesWrite = 'incidents:categories:write',
    IncidentsClose = 'incidents:close',
    IncidentsCloseRelated = 'incidents:close:related',
    IncidentsRead = 'incidents:read',
    IncidentsReadRelated = 'incidents:read:related',
    IncidentsWrite = 'incidents:write',
    IncidentsWriteRelated = 'incidents:write:related',
    InvoicesRead = 'invoices:read',
    InvoicesReadPersonal = 'invoices:read:personal',
    InvoicesReadRelated = 'invoices:read:related',
    LeadsCategoriesRead = 'leads:categories:read',
    leadsCategoriesWrite = 'leads:categories:write',
    LeadsFilesRead = 'files:leads:read',
    LeadsFilesWrite = 'files:leads:write',
    LeadsNotesRead = 'notes:leads:read',
    LeadsNotesWrite = 'notes:leads:write',
    LeadsRead = 'leads:read',
    LeadsWrite = 'leads:write',
    NewsCommentsRead = 'comments:news:read',
    NewsCommentsWrite = 'comments:news:write',
    NewsReadExternal = 'news:read:external',
    NewsReadInternal = 'news:read:internal',
    NewsWriteExternal = 'news:write:external',
    NewsWriteInternal = 'news:write:internal',
    Notes = 'notes',
    NotesRead = 'notes:read',
    NotesWrite = 'notes:write',
    OrderCategoriesRead = 'ordercategories:read',
    OrderCategoriesWrite = 'ordercategories:write',
    OrderFilesRead = 'files:order:read',
    OrderFilesWrite = 'files:order:write',
    OrderNotesRead = 'notes:order:read',
    OrderNotesWrite = 'notes:order:write',
    OrdersRead = 'orders:read',
    OrdersReadRelated = 'orders:read:related',
    OrdersWrite = 'orders:write',
    OrganizationFilesRead = 'files:organization:read',
    OrganizationFilesWrite = 'files:organization:write',
    OrganizationNotesRead = 'notes:organization:read',
    OrganizationNotesWrite = 'notes:organization:write',
    OrganizationsRead = 'organizations:read',
    OrganizationsReadRelated = 'organizations:read:related',
    OrganizationsWrite = 'organizations:write',
    PeopleFilesRead = 'files:people:read',
    PeopleFilesWrite = 'files:people:write',
    PeopleNotesRead = 'notes:people:read',
    PeopleNotesWrite = 'notes:people:write',
    PeopleRead = 'people:read',
    PeopleReadRelated = 'people:read:related',
    PeopleWrite = 'people:write',
    PollsRead = 'polls:read',
    PollsWrite = 'polls:write',
    ProductFilesRead = 'files:product:read',
    ProductFilesWrite = 'files:product:write',
    ProductNotesRead = 'notes:product:read',
    ProductNotesWrite = 'notes:product:write',
    ProductsCategoriesRead = 'products:categories:read',
    ProductsCategoriesWrite = 'products:categories:write',
    ProductsRead = 'products:read',
    ProductsWrite = 'products:write',
    ProfileFilesRead = 'files:profile:read',
    ProfileFilesWrite = 'files:profile:write',
    ProfileNotesRead = 'notes:profile:read',
    ProfileNotesWrite = 'notes:profile:write',
    ProfileRead = 'profile:read',
    ProfileWrite = 'profile:write',
    ProjectFilesRead = 'files:project:read',
    ProjectFilesWrite = 'files:project:write',
    ProjectNotesRead = 'notes:project:read',
    ProjectNotesWrite = 'notes:project:write',
    ProjectsRead = 'projects:read',
    ProjectsRoleBased = 'projects:roles',
    ProjectsWrite = 'projects:write',
    ProjectTaskCommentsRead = 'comments:project:task:read',
    ProjectTaskCommentsWrite = 'comments:project:task:write',
    ProjectTaskFilesRead = 'files:project:task:read',
    ProjectTaskFilesWrite = 'files:project:task:write',
    ResourceFilesRead = 'files:resource:read',
    ResourceFilesWrite = 'files:resource:write',
    ResourceNotesRead = 'notes:resource:read',
    ResourceNotesWrite = 'notes:resource:write',
    ResourcesRead = 'resources:read',
    ResourcesReadRelated = 'resources:read:related',
    ResourcesWrite = 'resources:write',
    ResourceCategoriesRead = 'resources:categories:read',
    ResourceCategoriesWrite = 'resources:categories:write',
    RolesRead = 'roles:read',
    RolesWrite = 'roles:write',
    RoutineCategoriesRead = 'routines:categories:read',
    RoutineCategoriesWrite = 'routines:categories:write',
    RoutineCommentsRead = 'comments:routine:read',
    RoutineCommentsWrite = 'comments:routine:write',
    RoutineFilesRead = 'files:routines:read',
    RoutineFilesWrite = 'files:routines:write',
    RoutinesExecute = 'routines:execute',
    RoutinesExecuteActor = 'routines:execute:actor',
    RoutinesExecuteRelated = 'routines:execute:related',
    RoutinesRead = 'routines:read',
    RoutinesReadActor = 'routines:read:actor',
    RoutinesReadRelated = 'routines:read:related',
    RoutinesWrite = 'routines:write',
    RoutinesWriteActor = 'routines:write:actor',
    RoutinesWriteRelated = 'routines:write:related',
    SensorNotesRead = 'notes:sensor:read',
    SensorNotesWrite = 'notes:sensor:write',
    SensorsRead = 'sensors:read',
    SensorsWrite = 'sensors:write',
    //SharedFoldersRead = 'sharedfolders:read',
    //SharedFoldersWrite = 'sharedfolders:write',
    SubscriptionNotesRead = 'notes:subscription:read',
    SubscriptionNotesWrite = 'notes:subscription:write',
    SubscriptionsRead = 'subscriptions:read',
    SubscriptionsTransfer = 'subscriptions:transfer',
    SubscriptionsWrite = 'subscriptions:write',
    SupplierCategoriesWrite = 'suppliers:categories:write',
    SupplierCategoriesRead = 'suppliers:categories:read',
    SupplierAndCusomerCategoriesWrite = 'suppliersandcustomers:categories:write',
    TagsRead = 'tags:read',
    TagsWrite = 'tags:write',
    TicketCommentsRead = 'comments:ticket:read',
    TicketCommentsWrite = 'comments:ticket:write',
    TicketFilesRead = 'files:ticket:read',
    TicketFilesWrite = 'files:ticket:write',
    TicketsClose = 'tickets:close',
    TicketsCloseRelated = 'tickets:close:related',
    TicketsRead = 'tickets:read',
    TicketsReadRelated = 'tickets:read:related',
    TicketsWrite = 'tickets:write',
    TicketsWriteRelated = 'tickets:write:related',
    MenuAllFiles = 'menu:allfiles',
    MenuAllItems = 'menu:all',
    MenuApplications = 'menu:applications',
    MenuCalendar = 'menu:calendar',
    MenuCalls = 'menu:calls',
    MenuChannels = 'menu:channels',
    MenuConfigExternalLinks = 'menu:configexternallinks',
    MenuContactPoints = 'menu:contactpoints',
    MenuDashboard = 'menu:dashboard',
    MenuDashboardSetup = 'menu:dashboardsetup',
    MenuEmployeeReport = 'menu:employeereport',
    MenuEmployees = 'menu:employees',
    MenuExternalLinks = 'menu:externallinks',
    MenuExternalLogins = 'menu:externallogins',
    MenuHours = 'menu:hours',
    MenuHoursConfirm = 'menu:hours:confirm',
    MenuHoursTransfer = 'menu:hours:transfer',
    MenuIncidentCategories = 'menu:incidents:categories',
    MenuIncidents = 'menu:incidents',
    MenuInvoices = 'menu:invoices',
    MenuLeads = 'menu:leads',
    MenuMeetings = 'menu:meetings',
    MenuNews = 'menu:news',
    MenuNewsManagement = 'menu:news:management',
    MenuOrders = 'menu:orders',
    MenuOrdersCategories = 'menu:orders:categories',
    MenuOrderReport = 'menu:orderreport',
    MenuOrganizations = 'menu:organizations',
    MenuPeople = 'menu:people',
    MenuPolls = 'menu:polls',
    MenuProducts = 'menu:products',
    MenuProductsCategories = 'menu:products:categories',
    MenuProjectReport = 'menu:projectreport',
    MenuProjects = 'menu:projects',
    MenuProjectTasks = 'menu:projects:tasks',
    MenuResources = 'menu:resources',
    MenuRevenueReport = 'menu:revenuereport',
    MenuRoles = 'menu:roles',
    MenuRoutineCategories = 'menu:routines:categories',
    MenuRoutines = 'menu:routines',
    MenuApproveAbsence = 'menu:hours:approveabsence',
    MenuSensors = 'menu:sensors',
    MenuSharedFolders = 'menu:sharedfolders',
    MenuSubscriptionReport = 'menu:subscriptionreport',
    MenuSubscriptions = 'menu:subscriptions',
    MenuSubscriptionsTransfer = 'menu:subscriptions:transfer',
    MenuTags = 'menu:tags',
    MenuTickets = 'menu:tickets',
    MenuTimeTracking = 'menu:timetracking',
    MenuLeadCategories = 'menu:leads:categories',
    MenuResourceCategories = 'menu:resources:categories',
    MenuCustomerCategories = 'menu:customer:categories',
    MenuSupplierCategories = 'menu:supplier:categories',
    MenuVacationReport = 'menu:vacationreport',
    MenuSelfCertificationReport = 'menu:selfcertificationreport',
    MenuIllnessChildReport = 'menu:illnesschildreport',
    MenuHourBank = 'menu:hourbank',
    MenuReportBuilder = 'menu:reportbuilder',
    OtherAttributes = 'other:attributes',
    OtherCalendars = 'other:calendars',
    OtherCallNotification = 'other:callnotification',
    OtherCallOrganization = 'other:callnotification:organization',
    OtherPictureOfTheDay = 'other:pictureoftheday',
    OtherTracking = 'other:tracking',
    ReportsReadEmployeeAll = 'reports:read:employee',
    ReportsReadEmployeePersonal = 'reports:read:employee:personal',
    ReportsReadOrder = 'reports:read:order',
    ReportsReadProject = 'reports:read:project',
    ReportsReadRevenue = 'reports:read:revenue',
    ReportsReadSubscription = 'reports:read:subscription',
    ReportsReadVacation = 'reports:read:vacation',
    ReportsReadSelfCertification = 'reports:read:selfcertification',
    ReportsReadIllnessChild = 'reports:read:illnesschild',
    ReportsReadHourBank = 'reports:read:hourbank',
    MeasuringPointsReadRelated = 'measuringpoints:read:related',
    MeasuringPointsRead = 'measuringpoints:read',
    MeasuringPointsWrite = 'measuringpoints:write',
    MenuMeasuringPoints = 'menu:measuringpoints',
    AdminNotificationCreate = 'notifications:write',
    ReportWrite = 'reports:write',
    ReportRead = 'reports:read'
}
