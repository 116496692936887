import { Tooltip } from "antd";
import { ActivitySortOption, ActivityType, ActivityView, SortDirection } from "../../ApiClient/swagger/data-contracts";
import CountUpDuration from "../../Modules/Shared/CountUpDuration";
import { RiStopCircleLine } from "react-icons/ri";
import { Drawer } from "../../Modules/Shared/Drawer";
import ActivityUpdateForm from "../../Modules/Activities/ActivityUpdateForm";
import { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";

const TrackingHeader = () => {
    const context = useContext(AppContext);

    const [showStopActivityDrawer, setShowStopActivityDrawer] = useState<boolean>(false);
    const [runningActivity, setRunningActivity] = useState<ActivityView>();

    const loadRunningActivities = useCallback(async () => {
        const response = await client.activities.queryActivities({
            actorId: context.user?.actorId,
            running: true,
            deleted: false,
            sortBy: ActivitySortOption.End,
            sortDirection: SortDirection.Desc,
            from: 0,
            limit: 5,
        });

        if (response) {
            const currentlyRunning = response.data.items.find(a => a.end == null);
            setRunningActivity(currentlyRunning);
        }

    }, [context.user?.actorId]);

    useEffect(() => {
        loadRunningActivities();
    }, [loadRunningActivities]);

    const onActivityEvent = useCallback((activity: ActivityView) => {
        if (runningActivity?.id == activity.id) {
            if (runningActivity.end == null && activity.end != null)
                setRunningActivity(null);
            else if (runningActivity.end == null && activity.end == null) //Replace the "fake activity" from activity form
                setRunningActivity(activity);
        }

        if (activity?.actor?.id == context.user?.actorId) {
            if (activity.end == null) {
                setRunningActivity(activity);
            }
        }
    }, [context.user?.actorId, runningActivity]);

    const onDeletedActivityEvent = useCallback((activity: ActivityView) => {
        if (activity.actor?.id === context.user?.actorId) {
            if (runningActivity && runningActivity.id === activity.id) {
                setRunningActivity(null);
            }
        }
    }, [context.user?.actorId, runningActivity]);

    useEffect(() => {
        context.events?.activities.onMany({
            "created": onActivityEvent,
            "updated": onActivityEvent,
            "deleted": onDeletedActivityEvent,
            "restored": onActivityEvent
        });

        return () => {
            context.events?.activities.offMany({
                "created": onActivityEvent,
                "updated": onActivityEvent,
                "deleted": onDeletedActivityEvent,
                "restored": onActivityEvent
            });
        }
    }, [context.events?.activities, onActivityEvent, onDeletedActivityEvent]);

    const trackingOrgName = runningActivity?.type == ActivityType.Customer || runningActivity?.type == ActivityType.Project ? runningActivity?.relation ? runningActivity?.relation.name : null : null;
    const trackingTaskName = runningActivity?.type == ActivityType.Project && runningActivity?.task ? runningActivity?.task.name : null;
    const trackingCategoryName = runningActivity?.category ? runningActivity?.category.name : null;

    return (
        runningActivity == null ? null : (
            <div className="stop-tracking-container">
                <div className="stop-tracking-content">
                    <div className="stop-tracking-description">
                        <Tooltip title={trackingTaskName ?? trackingCategoryName}><span className="description">{trackingOrgName ?? null}{trackingOrgName ? " / " : null}{trackingTaskName ?? trackingCategoryName}</span></Tooltip>
                        <span className="counter"><CountUpDuration start={runningActivity.start} /></span>
                    </div>
                    <div className="stop-tracking-action"><RiStopCircleLine style={{ color: "#ff4d4f", width: "35", height: "35" }} onClick={() => setShowStopActivityDrawer(true)} /></div>
                </div>
                <Drawer
                    title="Stop tracking"
                    closable
                    onClose={() => setShowStopActivityDrawer(false)}
                    open={showStopActivityDrawer}
                    component={
                        <ActivityUpdateForm
                            activity={runningActivity ?? null}
                            actorId={context.user.actorId}
                            onClose={(response) => {
                                if (response && runningActivity?.id == response.id) setRunningActivity(null);
                                setShowStopActivityDrawer(false);
                            }}
                        />
                    }
                    destroyOnClose
                />
            </div>
        )
    );
}

export default TrackingHeader;