/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateReport,
  ReportSortOption,
  ReportView,
  ReportViewEntityViewModel,
  ReportViewPagedReportQueryCollectionViewModel,
  SortDirection,
  UpdateReport,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Reports<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Report
   * @name GetReportById
   * @request GET:/api/reports/{id}
   * @secure
   */
  getReportById = (id: string, params: RequestParams = {}) =>
    this.http.request<ReportViewEntityViewModel, any>({
      path: `/api/reports/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Report
   * @name UpdateReport
   * @request PUT:/api/reports/{id}
   * @secure
   */
  updateReport = (id: string, data: UpdateReport, params: RequestParams = {}) =>
    this.http.request<ReportView, any>({
      path: `/api/reports/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Report
   * @name DeleteReport
   * @request DELETE:/api/reports/{id}
   * @secure
   */
  deleteReport = (id: string, params: RequestParams = {}) =>
    this.http.request<ReportView, any>({
      path: `/api/reports/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Report
   * @name CreateReport
   * @request POST:/api/reports
   * @secure
   */
  createReport = (data: CreateReport, params: RequestParams = {}) =>
    this.http.request<ReportView, any>({
      path: `/api/reports`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Report
   * @name QueryReports
   * @request GET:/api/reports
   * @secure
   */
  queryReports = (
    query?: {
      sortBy?: ReportSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ReportViewPagedReportQueryCollectionViewModel, any>({
      path: `/api/reports`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Report
   * @name RestoreReport
   * @request POST:/api/reports/{id}/restore
   * @secure
   */
  restoreReport = (id: string, params: RequestParams = {}) =>
    this.http.request<ReportView, any>({
      path: `/api/reports/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
