import { useCallback, useEffect, useRef, useState } from "react";
import client from "../../ApiClient/client";
import { ActorType, ContactPointLinkView, ContactPointSearchView, ContactPointType, ContactType } from "../../ApiClient/swagger/data-contracts";
import { AutoComplete, Avatar, Button, Card, Empty, Spin, Table } from "antd";
import { BankOutlined, MailOutlined, PhoneOutlined, QuestionCircleOutlined, UserOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { ActorLink } from "../../Modules/Actors";
import Meta from "antd/es/card/Meta";
import _ from "lodash";
import { Link } from "react-router-dom";


const SearchAndCallHeader = () => {
    const [contactPoints, setContactPoints] = useState<ContactPointSearchView[]>([]);
    const [value, setValue] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const containerRef = useRef(null);

    const debouncedSearch = useCallback(
        _.debounce(async (value: string) => {
            setLoading(true);
            if (value == undefined || value == null || value.trim() == "" || !value || value.trim().length < 2) {
                setContactPoints([]);
            } else {
                const response = await client.contactPoints.searchContactPoints(value).catch(() => { setContactPoints([]); });
                if (response) {
                    setContactPoints(response.data);
                }
            }
            setLoading(false);
        }, 300), // Adjust the debounce delay as needed
        []
    );

    useEffect(() => {
        return () => {
            debouncedSearch.cancel(); // Cancel the debounce on unmount
        };
    }, [debouncedSearch]);

    const handleFocus = () => {
        setShowDropdown(true);
    };

    const handleBlur = (event) => {
        if (containerRef.current && containerRef.current.contains(event.relatedTarget)) {
            return;
        }
        setShowDropdown(false);
    };

    const onSearchAndCallClick = (contactPoint: ContactPointLinkView) => {
        if (!contactPoint) return;
        if (contactPoint.type == ContactPointType.Phone) {
            window.location.href = `tel:${contactPoint.value}`;
        }
        else if (contactPoint.type == ContactPointType.Video) {
            window.location.href = `sips:${contactPoint.value}`;
        }
        else if (contactPoint.type == ContactPointType.Email) {
            window.location.href = `mailto:${contactPoint.value}`;
        }
    }

    const icons = {
        [ContactPointType.Phone]: <PhoneOutlined />,
        [ContactPointType.Video]: <VideoCameraOutlined />,
        [ContactPointType.Email]: <MailOutlined />,
        [ContactPointType.Other]: <QuestionCircleOutlined />
    };

    return (
        <AutoComplete
            className="search-and-call"
            allowClear
            placeholder='Search & call'
            onSearch={debouncedSearch}
            options={contactPoints}
            notFoundContent={<Empty />}
            popupMatchSelectWidth={false}
            onSelect={null}
            placement="bottomLeft"
            value={value}
            onChange={(value) => setValue(value)}
            open={showDropdown && value != ""}   
            onBlur={handleBlur}
            onFocus={handleFocus}
            dropdownRender={() => {
                if (loading) {
                    return <Spin spinning style={{ width: "500px", marginBlock: "10px" }} />
                }

                if (contactPoints == null || contactPoints.length == 0) {
                    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No result" style={{ width: "500px", marginBlock: "10px" }} />
                }

                return (
                    <div className="search-and-call-menu" onMouseDown={(e) => e.preventDefault()}>
                        {contactPoints.map((contactPoint) => {
                            const title = contactPoint.contact != null ? <ActorLink disablePopover {...contactPoint.contact} actorType={contactPoint.contact.contactType.toString() as ActorType} /> : null;
                            if (contactPoint.contact != null) {
                                return (
                                        <Card
                                            key={contactPoint.contact.id}
                                            className="searchAndCall-card"
                                        >
                                            <Meta
                                                avatar={<Avatar src={`/api/people/${contactPoint.contact.id}/avatar`} icon={contactPoint.contact.contactType === ContactType.Organization ? <BankOutlined /> : <UserOutlined />} />}
                                                title={title}
                                                description={contactPoint.contact.contactType != ContactType.Organization ?
                                                    _.map(contactPoint.organizations || [], org => {
                                                        return <div key={org.id}><Link to={`organizations/${org.id}`} onClick={(e) => e.stopPropagation()}>{org.name}</Link></div>
                                                    }) : null
                                                }
                                            />
                                            <Table
                                                className="cp-result-values-table"
                                                columns={[
                                                    {
                                                        render: (cp: ContactPointLinkView) => <span>{icons[cp.type]} {cp.type}</span>,
                                                        key: "cpType",
                                                        width: 80
                                                    },
                                                    {
                                                        render: (cp: ContactPointLinkView) => cp.value,
                                                        key: "cpVal"
                                                    }
                                                ]}
                                                dataSource={_.map(contactPoint.contactPoints || [], cp => { return Object.assign({}, cp, { key: cp.id }); })}
                                                size="small"
                                                pagination={false}
                                                onRow={(data) => {
                                                    return {
                                                        onClick: event => {
                                                            event.stopPropagation();
                                                            onSearchAndCallClick(data);
                                                        }
                                                    };
                                                }}
                                            />
                                        </Card>
                                )
                            } else {
                                if (contactPoint.contactPoints != null && contactPoint.contactPoints.length == 1) {
                                    const cp = contactPoint.contactPoints[0];
                    
                                    let icon = <UserOutlined />;
                                    if (cp.type == ContactPointType.Phone)
                                        icon = <PhoneOutlined />;
                                    else if (cp.type == ContactPointType.Video)
                                        icon = <VideoCameraOutlined />;
                                    else if (cp.type == ContactPointType.Email)
                                        icon = <MailOutlined />;
                    
                                    return (
                                        <Card
                                            key={cp.id}
                                            className="searchAndCall-card cp-result"
                                            actions={[
                                                <Button key="phone" className="searchAndCall-action-btn" icon={<PhoneOutlined />} shape="circle" disabled={cp.type != ContactPointType.Phone} onClick={() => onSearchAndCallClick(cp)} />,
                                                <Button key="video" className="searchAndCall-action-btn" icon={<VideoCameraOutlined />} shape="circle" disabled={cp.type != ContactPointType.Video} onClick={() => onSearchAndCallClick(cp)} />,
                                                <Button key="mail" className="searchAndCall-action-btn" icon={<MailOutlined />} shape="circle" disabled={cp.type != ContactPointType.Email} onClick={() => onSearchAndCallClick(cp)} />,
                                            ]}
                                        >
                                            <Meta
                                                avatar={<Avatar icon={icon} />}
                                                title={cp.value}
                                                description={cp.type}
                                            />
                                        </Card>
                                    );
                                }
                            }
                        })}
                    </div>
                )
                
            }}
        />
    );
}

export default SearchAndCallHeader;