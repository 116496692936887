/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ActorRelatedNotesView,
  AddOrUpdateAddressCollectionItem,
  AddOrUpdateCustomerInfo,
  AddOrUpdateSupplierInfo,
  AddressCollectionItem,
  CreateFileOrFolder,
  CreateNote,
  CreateOrUpdatePersonWebRequest,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  Handler,
  HandlerType,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  NoteView,
  NoteViewEntityViewModel,
  NoteViewPagedNoteQueryCollectionViewModel,
  OrganizationAccessLevel,
  OrganizationRole,
  PersonAggregationResultPersonAggregateQueryAggregationResults,
  PersonAggregationTypes,
  PersonSortOption,
  PersonView,
  PersonViewEntityViewModel,
  PersonViewPagedPersonQueryCollectionViewModel,
  PreviewType,
  RecentCustomerView,
  RevertFileToVersion,
  SortDirection,
  UpdateFileOrFolder,
  UpdateNote,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class People<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags PeopleNote
   * @name GetPersonNoteById
   * @request GET:/api/people/{entityId}/notes/{noteId}
   * @secure
   */
  getPersonNoteById = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteViewEntityViewModel, any>({
      path: `/api/people/${entityId}/notes/${noteId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PeopleNote
   * @name UpdatePersonNote
   * @request PUT:/api/people/{entityId}/notes/{noteId}
   * @secure
   */
  updatePersonNote = (entityId: string, noteId: string, data: UpdateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/people/${entityId}/notes/${noteId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PeopleNote
   * @name DeletePersonNote
   * @request DELETE:/api/people/{entityId}/notes/{noteId}
   * @secure
   */
  deletePersonNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/people/${entityId}/notes/${noteId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PeopleNote
   * @name CreatePersonNote
   * @request POST:/api/people/{entityId}/notes
   * @secure
   */
  createPersonNote = (entityId: string, data: CreateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/people/${entityId}/notes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PeopleNote
   * @name QueryPersonNotes
   * @request GET:/api/people/{entityId}/notes
   * @secure
   */
  queryPersonNotes = (
    entityId: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      private?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NoteViewPagedNoteQueryCollectionViewModel, any>({
      path: `/api/people/${entityId}/notes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PeopleNote
   * @name RestorePersonNote
   * @request POST:/api/people/{entityId}/notes/{noteId}/restore
   * @secure
   */
  restorePersonNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/people/${entityId}/notes/${noteId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name GetPersonById
   * @request GET:/api/people/{id}
   * @secure
   */
  getPersonById = (id: string, params: RequestParams = {}) =>
    this.http.request<PersonViewEntityViewModel, any>({
      path: `/api/people/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name UpdatePerson
   * @request PUT:/api/people/{id}
   * @secure
   */
  updatePerson = (id: string, data: CreateOrUpdatePersonWebRequest, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name DeletePerson
   * @request DELETE:/api/people/{id}
   * @secure
   */
  deletePerson = (id: string, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name CreatePerson
   * @request POST:/api/people
   * @secure
   */
  createPerson = (data: CreateOrUpdatePersonWebRequest, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name QueryPeople
   * @request GET:/api/people
   * @secure
   */
  queryPeople = (
    query?: {
      sortBy?: PersonSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      organizationId?: string;
      isEmployee?: boolean;
      accessLevels?: OrganizationAccessLevel[];
      handlerId?: string;
      handlerRoles?: HandlerType[];
      businessRelationTypes?: string[];
      /** @format date-time */
      notCalledSince?: string;
      hasActiveSubscription?: boolean;
      tagIds?: string[];
      isSupplier?: boolean;
      isCustomer?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PersonViewPagedPersonQueryCollectionViewModel, any>({
      path: `/api/people`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name RestorePerson
   * @request POST:/api/people/{id}/restore
   * @secure
   */
  restorePerson = (id: string, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name UpdatePersonAttributes
   * @request POST:/api/people/{id}/attributes
   * @secure
   */
  updatePersonAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name AddOrUpdatePersonOrganizationRole
   * @request PUT:/api/people/{id}/organizations/{organizationId}
   * @secure
   */
  addOrUpdatePersonOrganizationRole = (
    id: string,
    organizationId: string,
    data: OrganizationRole,
    params: RequestParams = {},
  ) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/organizations/${organizationId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name DeletePersonOrganizationRole
   * @request DELETE:/api/people/{id}/organizations/{organizationId}
   * @secure
   */
  deletePersonOrganizationRole = (id: string, organizationId: string, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/organizations/${organizationId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name GetPersonAvatar
   * @request GET:/api/people/{id}/avatar
   * @secure
   */
  getPersonAvatar = (
    id: string,
    query?: {
      /** @format uuid */
      version?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/people/${id}/avatar`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name UpdatePersonAvatar
   * @request POST:/api/people/{id}/avatar
   * @secure
   */
  updatePersonAvatar = (
    id: string,
    data: {
      /** @format binary */
      avatar: File;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/avatar`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name DeletePersonAvatar
   * @request DELETE:/api/people/{id}/avatar
   * @secure
   */
  deletePersonAvatar = (id: string, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/avatar`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name GetEmployeeRecentCustomers
   * @request GET:/api/people/{id}/recentCustomers
   * @secure
   */
  getEmployeeRecentCustomers = (id: string, params: RequestParams = {}) =>
    this.http.request<RecentCustomerView[], any>({
      path: `/api/people/${id}/recentCustomers`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name AddOrUpdatePersonHandler
   * @request PUT:/api/people/{id}/handlers/{employeeId}
   * @secure
   */
  addOrUpdatePersonHandler = (id: string, employeeId: string, data: Handler, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/handlers/${employeeId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name RemovePersonHandler
   * @request DELETE:/api/people/{id}/handlers/{employeeId}
   * @secure
   */
  removePersonHandler = (id: string, employeeId: string, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/handlers/${employeeId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name AddOrUpdatePersonCustomerInfo
   * @request POST:/api/people/{id}/customerInfo
   * @secure
   */
  addOrUpdatePersonCustomerInfo = (id: string, data: AddOrUpdateCustomerInfo, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/customerInfo`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name DeletePersonCustomerInfo
   * @request DELETE:/api/people/{id}/customerInfo
   * @secure
   */
  deletePersonCustomerInfo = (id: string, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/customerInfo`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name AddOrUpdatePersonSupplierInfo
   * @request POST:/api/people/{id}/supplierInfo
   * @secure
   */
  addOrUpdatePersonSupplierInfo = (id: string, data: AddOrUpdateSupplierInfo, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/supplierInfo`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name DeletePersonSupplierInfo
   * @request DELETE:/api/people/{id}/supplierInfo
   * @secure
   */
  deletePersonSupplierInfo = (id: string, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/${id}/supplierInfo`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name CreatePersonAddress
   * @request POST:/api/people/{id}/addresses
   * @secure
   */
  createPersonAddress = (id: string, data: AddOrUpdateAddressCollectionItem, params: RequestParams = {}) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/people/${id}/addresses`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name UpdatePersonAddress
   * @request PUT:/api/people/{id}/addresses/{addressId}
   * @secure
   */
  updatePersonAddress = (
    id: string,
    addressId: string,
    data: AddOrUpdateAddressCollectionItem,
    params: RequestParams = {},
  ) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/people/${id}/addresses/${addressId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name DeletePersonAddress
   * @request DELETE:/api/people/{id}/addresses/{addressId}
   * @secure
   */
  deletePersonAddress = (id: string, addressId: string, params: RequestParams = {}) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/people/${id}/addresses/${addressId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name UpdatePersonAddressAttributes
   * @request POST:/api/people/{id}/addresses/{addressId}/attributes
   * @secure
   */
  updatePersonAddressAttributes = (
    id: string,
    addressId: string,
    data: Record<string, string>,
    params: RequestParams = {},
  ) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/people/${id}/addresses/${addressId}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name GetPersonRelatedNotes
   * @request GET:/api/people/{id}/relatedNotes
   * @secure
   */
  getPersonRelatedNotes = (
    id: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ActorRelatedNotesView[], any>({
      path: `/api/people/${id}/relatedNotes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name ConvertPersonFromOrganization
   * @request POST:/api/people/_convertFromOrganization/{id}
   * @secure
   */
  convertPersonFromOrganization = (id: string, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/people/_convertFromOrganization/${id}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name GetPeopleAggregates
   * @request GET:/aggs/people
   * @secure
   */
  getPeopleAggregates = (
    query?: {
      personId?: string;
      aggs?: PersonAggregationTypes[];
      organizationId?: string;
      isEmployee?: boolean;
      accessLevels?: OrganizationAccessLevel[];
      handlerId?: string;
      handlerRoles?: HandlerType[];
      businessRelationTypes?: string[];
      /** @format date-time */
      notCalledSince?: string;
      hasActiveSubscription?: boolean;
      tagIds?: string[];
      isSupplier?: boolean;
      isCustomer?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PersonAggregationResultPersonAggregateQueryAggregationResults, any>({
      path: `/aggs/people`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Person
   * @name GetPersonAggregatesById
   * @request GET:/aggs/people/{id}
   * @secure
   */
  getPersonAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/people/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name GetPersonFileById
   * @request GET:/api/people/{entityId}/files/{fileId}
   * @secure
   */
  getPersonFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/people/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name UpdatePersonFile
   * @request PUT:/api/people/{entityId}/files/{fileId}
   * @secure
   */
  updatePersonFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/people/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name DeletePersonFile
   * @request DELETE:/api/people/{entityId}/files/{fileId}
   * @secure
   */
  deletePersonFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/people/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name DownloadPersonFile
   * @request GET:/api/people/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadPersonFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/people/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name PreviewPersonFile
   * @request GET:/api/people/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewPersonFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/people/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name CreatePersonFile
   * @request POST:/api/people/{entityId}/files
   * @secure
   */
  createPersonFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/people/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name QueryPersonFiles
   * @request GET:/api/people/{entityId}/files
   * @secure
   */
  queryPersonFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/people/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name RestorePersonFile
   * @request POST:/api/people/{entityId}/files/{fileId}/restore
   * @secure
   */
  restorePersonFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/people/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name MovePersonFile
   * @request POST:/api/people/{entityId}/files/{fileId}/move
   * @secure
   */
  movePersonFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/people/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name RevertPersonFile
   * @request POST:/api/people/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertPersonFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/people/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name GetPersonFolderById
   * @request GET:/api/people/{entityId}/folders/{folderId}
   * @secure
   */
  getPersonFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/people/${entityId}/folders/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name UpdatePersonFolder
   * @request PUT:/api/people/{entityId}/folders/{folderId}
   * @secure
   */
  updatePersonFolder = (entityId: string, folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/people/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name DeletePersonFolder
   * @request DELETE:/api/people/{entityId}/folders/{folderId}
   * @secure
   */
  deletePersonFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/people/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name CreatePersonFolder
   * @request POST:/api/people/{entityId}/folders
   * @secure
   */
  createPersonFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/people/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name QueryPersonFolders
   * @request GET:/api/people/{entityId}/folders
   * @secure
   */
  queryPersonFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/people/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name RestorePersonFolder
   * @request POST:/api/people/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restorePersonFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/people/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PersonFile
   * @name MovePersonFolder
   * @request POST:/api/people/{entityId}/{folderId}/move
   * @secure
   */
  movePersonFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/people/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
