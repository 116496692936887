import _ from 'lodash';
import { Form as AntForm, Button, Alert, Popconfirm } from "antd";
import { useEffect } from 'react';
import { usePreviousValue } from '../../Hooks/usePreviousValue';

export enum FormType {
    Create = 'Create',
    Edit = 'Edit',
    Import = 'Import'
}

export interface FormProps {
    loading: boolean;
    error: string | null;
    type: FormType;
    isDeleted?: boolean;
    isSystemPreset?: boolean;
    includeClearButton?: boolean;
    disableSubmit?: boolean;
    onSubmit: (request: any) => void;
    onDelete?: () => void;
    onRestore?: () => void;
    initialValues?: any;
    onValuesChange?: (changedValues, allValues) => void;
    onCancel?: () => void;
    className?: string;
    hideActions?: boolean;
    customSubmit?: React.ReactNode;
    extraFormActions?: React.ReactNode;
    onClear?: () => void;
    onClose?: () => void;
    children?: React.ReactNode;
    deleteButtonText?: string;
}

interface BaseFormProps extends FormProps { }


function BaseForm(props: BaseFormProps) {

    const [form] = AntForm.useForm();
    const previousInitialValues = usePreviousValue(props.initialValues);

    function onClear() {
        form.resetFields();
        if (props.onClear) props.onClear();
    }

    async function onFinish(request) {
        await props.onSubmit(request);
    }

    function onFinishFailed({ errorFields }) {
        form.scrollToField(errorFields[0].name);
    }

    useEffect(() => {
        const isEqual = _.isEqual(props.initialValues, previousInitialValues);
        if (!props.loading && !isEqual) {
            _.each(props.initialValues ?? {}, (value, key) => {
                const currentValue = form.getFieldValue(key);
                if (currentValue === value) return;

                form.setFieldsValue({
                    [key]: value === undefined ? currentValue : value
                });
            });
        }
    }, [form, previousInitialValues, props.initialValues])

    const submitButton = props.onSubmit === null
        ? null
        : props.customSubmit != null
            ? props.customSubmit
            : props.isDeleted
                ? null
                : <Button disabled={props.disableSubmit} loading={props.loading} type="primary" htmlType="submit" size="large">{props.type == FormType.Edit
                    ? 'Save'
                    : props.type == FormType.Create
                        ? 'Done'
                        : props.type == FormType.Import
                            ? 'Import'
                            : null}
                </Button>;

    const deleteText = props.deleteButtonText ? props.deleteButtonText : "Delete";
    const cancelButton = props.onCancel ? <Button loading={props.loading} size="large" onClick={props.onCancel}>Cancel</Button> : null;
    const closeButton = props.onClose ? <Button loading={props.loading} size="large" onClick={props.onClose}>Close</Button> : null;
    const clearButton = !props.isDeleted && props.type != FormType.Edit ? <Button loading={props.loading} size="large" onClick={onClear}>Clear all</Button> : null;

    const deleteOrRestoreButton = props.onDelete && !props.isDeleted
        ? <Popconfirm title="Are you sure you want to delete?" onConfirm={props.onDelete} okText="Yes">
            <Button loading={props.loading} type="default" danger size="large">{deleteText}</Button>
        </Popconfirm>
        : props.onRestore && props.isDeleted
            ? <Button onClick={props.onRestore} loading={props.loading} type="default" size="large">Restore</Button>
            : null;

    const systemPresetMessage = props.isSystemPreset ? <Alert message="This is a system preset and can't be modified." type="info" showIcon /> : null;
    const errorAlert = props.error ? <Alert type="warning" message={props.error} showIcon className="warning-text" /> : null;


    return (
        <AntForm
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            hideRequiredMark
            autoComplete="off"
            initialValues={props.initialValues}
            onValuesChange={props.onValuesChange}
            className={props.className ?? ""}
        >
            {systemPresetMessage}
            {props.children}
            {props.hideActions ? null :
                <div className="form-actions">
                    {props.isSystemPreset ? null : submitButton}
                    {props.includeClearButton && props.onCancel ? clearButton : null}
                    {props.onCancel ? cancelButton : clearButton}
                    {props.onClose ? closeButton : null}
                    {props.isSystemPreset ? null : deleteOrRestoreButton}
                    {props.extraFormActions}
                </div>}
            {errorAlert}
        </AntForm>
    );
}

export default BaseForm;