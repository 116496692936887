import { MenuOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Typography } from "antd";
import { Header } from "antd/lib/layout/layout";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { Drawer } from "../../Modules/Shared/Drawer";
import AppMenu from "../AppMenu";
import CallHeaderDropdown from "./CallHeaderDropdown";
import ProfileHeaderDropdown from "./ProfileHeaderDropdown";
import NotificationDropDown from "./NotificationDropDown";
import eventEmitter from "../../Models/EventEmitter";
import ActionHeaderDropdown from "./ActionHeaderDropdown";
import TrackingHeader from "./TrackingHeader";
import SearchAndCallHeader from "./SearchAndCallHeader";

const { Text } = Typography;

function AppHeader({ menus }) {

    const context = useContext(AppContext);

    const MemoizedDesktopHeader = useMemo(() => <DesktopHeader />, []);
    const MemoizedMobileHeader = useMemo(() => <MobileHeader menus={menus} />, [menus]);

    return (
        <Header className={`app-header ${context.isMobile ? "mobile-header" : "desktop-header"}`}>
            <div className="header-container">
                {context.isMobile ? MemoizedMobileHeader : MemoizedDesktopHeader}
            </div>
        </Header>
    );
}

const AppLogo = () => {
    return useMemo(() => (
        <div className="overviu-logo-container">
            <div className="overviu-logo" />
        </div>
    ), []);
};


function DesktopHeader() {
    const context = useContext(AppContext);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.ctrlKey || e.shiftKey || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                eventEmitter.emit('prevent-header-keydown', e);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const MemoizedBreadcrumb = useMemo(() => (
        <Breadcrumb className="breadcrumb">
            {context.breadcrumb}
        </Breadcrumb>
    ), [context.breadcrumb]);

    return (
        <>
            {context.user?.isAuthenticated
                ? <Link to="/"><AppLogo /></Link>
                : <AppLogo />
            }

            {MemoizedBreadcrumb}

            <div className="header-actions">

                {!context?.user?.hasCapability(Capabilities.OtherTracking) ? null :
                    <div className="action-container">
                        <TrackingHeader />
                    </div>
                }

                {!context?.user?.hasCapability(Capabilities.ContactPointsRead) ? null :
                    <div className="search-and-call-container">
                        <SearchAndCallHeader />
                    </div>
                }

                <div className="action-container">
                    <ActionHeaderDropdown />
                </div>

                {!context?.user?.hasCapability(Capabilities.OtherCallNotification) ? null :
                    <div className="action-container">
                        <CallHeaderDropdown />
                    </div>
                }

                { //!context?.user?.hasCapability(Capabilities.AdminNotificationCreate) ? null :
                    <div className="action-container">
                        <NotificationDropDown />
                    </div>
                }

                <div className="action-container">
                    <ProfileHeaderDropdown />
                </div>
            </div>
        </>
    );
}
function MobileHeader({ menus }) {

    const [showMenu, setShowMenu] = useState(false);

    return (
        <>
            <div className="burger-container">
                <Button icon={<MenuOutlined />} type="primary" size="large" className="page-toggle-nav" onClick={() => setShowMenu((current) => !current)} />
            </div>

            <div className="logo-container">
                <Text className="header-text" strong style={{ fontSize: 24 }}>Overviu</Text>
            </div>

            <div className="profile-container">
                <ProfileHeaderDropdown />
            </div>

            <Drawer
                placement="left"
                open={showMenu}
                closable={false}
                onClose={() => setShowMenu((current) => !current)}
                className="nav nav-mobile"
            >
                <AppMenu menus={menus} onMenuClick={() => setShowMenu(false)} />
            </Drawer>
        </>
    );
}

export default AppHeader;