import _ from "lodash";
import moment from "moment";
import client from "../client";
import { ContactPointType, EmployeeView, PagedEmployeeQuery } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig } from "./BaseExportService";

export class EmployeeExport extends BaseExportService<PagedEmployeeQuery, EmployeeView> {

    constructor() {
        const specification: ExportConfig<EmployeeView>[] = [
            { header: 'Name', value: (data: EmployeeView) => data.name },
            {
                header: 'Email', value: (data: EmployeeView) => {
                    let address = "";
                    const mail = _.find(data.contactPoints, cp => cp.type === ContactPointType.Email);
                    if (mail) address = mail.value;

                    return address;
                }
            },
            {
                header: 'Phone', value: (data: EmployeeView) => {
                    let phoneNumber = "";
                    const phone = _.find(data.contactPoints, cp => cp.type === ContactPointType.Phone);
                    if (phone) phoneNumber = phone.value;

                    return phoneNumber;
                }
            },
            { header: 'Employee number', value: (data: EmployeeView) => data.employeeNumber },
            { header: 'Username', value: (data: EmployeeView) => data.name },
            { header: 'Roles', value: (data: EmployeeView) => data.organizationRoles == null ? null : _.map(data.organizationRoles, or => `${or.organization.name}: ${or.role}`).join(',') },
            { header: 'Birthday', value: (data: EmployeeView) => data.birthday ? moment(data.birthday).format() : "" },
            { header: 'Relative', value: (data: EmployeeView) => data.relative ?? "" },
            { header: 'Relative contact info', value: (data: EmployeeView) => data.relativeContactInfo ?? "" },
            { header: 'Last modified', value: (data: EmployeeView) => data.lastModified ? moment(data.lastModified).format() : null },
            {
                header: 'Attributes', value: (data: EmployeeView) => {
                    let dataString = "";

                    if (data.attributes) {
                        _.each(data.attributes, (key, value) => {
                            if (value != "rootFolderId") {
                                if (dataString.length > 0) dataString = dataString + ", ";

                                dataString = dataString + key + ": " + value;
                            }
                        })
                    }

                    return dataString;
                }
            },
            { header: 'Deleted', value: (data: EmployeeView) => data.deleted ? true : false }
        ];

        super(specification, client.employees.queryEmployees);
    }
}