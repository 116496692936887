import _ from 'lodash';
import { CloseOutlined, PhoneOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Divider, Dropdown } from "antd";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CallDirection, CallSortOption, CallStatus, CallTrackingType, CallView, SortDirection } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import CallDropdownItem from "./CallDropdownItem";
import { ActorIds } from "../../Definitions/_definitions";
import { Capabilities } from "../../Definitions/_capabilties";
import client from "../../ApiClient/client";
import eventEmitter from '../../Models/EventEmitter';


export default function CallHeaderDropdown() {

    const [visible, setDropdownVisible] = useState(false);
    const [error, setError] = useState<string>();

    const [loadMoreCalls, setLoadMoreCalls] = useState(false);
    const [loadingIgnore, setLoadingIgnoreCalls] = useState<string[]>([]);
    const [loadingIgnoreAll, setLoadingIgnoreAll] = useState(false);

    const [activeCalls, setActiveCalls] = useState<CallView[]>([]);
    const [callsToHandle, setCallsToHandle] = useState<CallView[]>([]);

    const context = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (context.user.actorId != ActorIds.System && context.user.hasCapability(Capabilities.CallsRead)) {
            getInitiatedCall();
            getUnhandledCalls();
        }
    }, [])

    useEffect(() => {
        context.events.calls.onMany({
            'created': onCallEvent,
            'updated': onCallEvent,
            'deleted': onDeletedCallEvent,
            'restored': onCallEvent
        });

        return () => {
            context.events.calls.offMany({
                'created': onCallEvent,
                'updated': onCallEvent,
                'deleted': onDeletedCallEvent,
                'restored': onCallEvent
            });
        }
    }, [activeCalls, callsToHandle])

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (visible) {
                e.stopImmediatePropagation();
            }
        }
        eventEmitter.on('prevent-header-keydown', handleKeyDown);

        return () => {
            eventEmitter.off('prevent-header-keydown', handleKeyDown);
        };
    }, [visible]);


    function onCallEvent(callEvent: CallView) {
        if (!callEvent) return;
        if (!validateCall(callEvent)) return;

        if (callEvent.status === CallStatus.Ringing || callEvent.status === CallStatus.Connected) {
            addToActiveCalls(callEvent);
        }
        else if (callEvent.status === CallStatus.Completed || callEvent.status === CallStatus.Missed) {
            const tempCallsToHandle = callsToHandle?.slice() ?? [];

            const isReferredByCurrentUser = callEvent.transfer != null && callEvent.transfer.referredBy != null && callEvent.transfer.referredBy.contact != null && callEvent.transfer.referredBy.contact.id === context.user.actorId;

            if ((callEvent.direction == CallDirection.Incoming || callEvent.direction === CallDirection.Outgoing) && !isReferredByCurrentUser) {
                const existingHandle = _.find(tempCallsToHandle, c => { return c.id === callEvent.id; });
                const handleIndex = existingHandle != null ? tempCallsToHandle.indexOf(existingHandle) : -1;

                const isIgnoredByActor = _.find(callEvent.tracking.ignored, actor => actor.id === context.user.actorId) != null;
                const isTrackedByActor = _.find(callEvent.tracking.completed, actor => actor.id === context.user.actorId) != null;

                if (handleIndex !== -1) {
                    if (isIgnoredByActor || isTrackedByActor)
                        tempCallsToHandle.splice(handleIndex, 1);
                    else
                        tempCallsToHandle[handleIndex] = callEvent;
                }
                else {
                    if (!isIgnoredByActor && !isTrackedByActor)
                        tempCallsToHandle.push(callEvent);
                }
            }

            const tempActiveCalls = activeCalls?.slice() ?? [];
            const existingActive = _.find(tempActiveCalls, c => c.id == callEvent.id);
            const activeIndex = existingActive != null ? tempActiveCalls.indexOf(existingActive) : -1;

            if (activeIndex != -1) {
                tempActiveCalls.splice(activeIndex, 1);

                if (callEvent.direction == CallDirection.Internal && tempActiveCalls.length == 0)
                    setDropdownVisible(false);
            }

            const sortedCallsToHandle = _.sortBy(tempCallsToHandle, "end").reverse();

            setActiveCalls(tempActiveCalls);
            setCallsToHandle(sortedCallsToHandle);
        }
    }

    function onDeletedCallEvent(callEvent: CallView) {
        if (!callEvent) return;

        const tempCalls = callsToHandle?.slice() ?? [];
        const index = _.findIndex(tempCalls, c => c.id === callEvent.id);
        if (index !== -1) {
            tempCalls.splice(index, 1);
            setCallsToHandle(tempCalls);
        }
    }

    async function getUnhandledCalls() {
        const response = await client.calls
            .queryCalls({
                contactId: context.user.actorId,
                trackingTypes: [CallTrackingType.Pending],
                statuses: [CallStatus.Completed, CallStatus.Missed],
                internal: false,
                sortBy: CallSortOption.End,
                sortDirection: SortDirection.Desc,
                deleted: false,
                from: 0,
                limit: 999
            })
            .catch(exception => setError(exception.error));

        if (response) {
            setCallsToHandle(response.data.items);
        }
    }

    async function getInitiatedCall() {
        const response = await client.calls
            .queryCalls({
                contactId: context.user.actorId,
                statuses: [CallStatus.Ringing, CallStatus.Connected],
                trackingTypes: [CallTrackingType.Pending],
                sortBy: CallSortOption.End,
                sortDirection: SortDirection.Desc
            })
            .catch(exception => setError(exception.error));

        if (response) {
            _.each(response.data.items, call => validateCall(call) ? addToActiveCalls(call) : null);
        }
    }

    function addToActiveCalls(call: CallView) {
        if (!call) return;

        const calls = activeCalls.slice();
        const existing = _.find(calls, c => { return c.id == call.id; });

        if (existing != null) {
            const index = calls.indexOf(existing);
            calls[index] = call;
        }
        else {
            calls.push(call);
            setDropdownVisible(true);
        }

        setActiveCalls(calls);
    }

    function validateCall(call: CallView) {
        if (context.user.actorId == ActorIds.System) return false;
        if (!call || call == null) return false;
        if (call.deleted) return false;

        const { to, from } = call;

        const isUserCall = (to.contact && to.contact.id == context.user.actorId)
            || (from.contact && from.contact.id == context.user.actorId);

        let isCompanyCall = false;
        if (context.user.hasCapability(Capabilities.OtherCallOrganization)) {
            if (to.contact && Object.keys(context.user.companies).includes(to.contact.id))
                isCompanyCall = true;
        }

        if (!isUserCall && !isCompanyCall) return false;

        return true;
    }

    //TODO: Move to dropdown-item
    async function ignoreCall(e, call: CallView) {
        e.stopPropagation();

        const tempLoadingIgnore = loadingIgnore?.slice() ?? [];
        if (tempLoadingIgnore[call.id] == null) {
            tempLoadingIgnore.push(call.id);
            setLoadingIgnoreCalls(tempLoadingIgnore);
        }

        const calls = callsToHandle.slice();
        const existing = _.find(calls, c => { return c.id == call.id });

        if (existing != null) {
            const index = calls.indexOf(existing);

            if (index != -1) {
                calls.splice(index, 1);
            }

            await client.calls.ignoreCalls([call.id]).catch(exception => setError(exception.error));

            setCallsToHandle(calls);

            if (calls.length == 0 && (activeCalls == null || activeCalls.length == 0))
                setDropdownVisible(false);
        }

        const ignoreIndex = tempLoadingIgnore.indexOf(call.id);
        tempLoadingIgnore.splice(ignoreIndex, 1);
        setLoadingIgnoreCalls(tempLoadingIgnore);
    }

    async function ignoreAllCalls(e) {
        e.stopPropagation();

        //const callIds = _.map(callsToHandle, "id");
        //setLoadingIgnoreCalls(callIds);
        setLoadingIgnoreAll(true);
        try {
            await client.calls.ignoreAllCalls();
        }
        catch (error: any) {
            setError(error.message);
        }
        //try {

        //    const callIdBatches = _.chunk(callIds, 20);

        //    for (const callIdBatch of callIdBatches) {
        //        await client.calls.ignoreCalls(callIdBatch);
        //    }

        setCallsToHandle([]);

        //    if (activeCalls == null || activeCalls.length == 0)
        //        setDropdownVisible(false);
        //}
        //catch (error: any) {
        //    setError(error.message);
        //}

        //setLoadingIgnoreCalls([]);
        setLoadingIgnoreAll(false);
    }

    function onTrackCallFinished(trackedCall: CallView) {
        const tempCalls = callsToHandle.slice();
        const existing = _.find(tempCalls, c => c.id === trackedCall.id);

        if (existing != null) {
            const index = tempCalls.indexOf(existing);

            if (index != -1)
                tempCalls.splice(index, 1);
        }

        setCallsToHandle(tempCalls);

        if (tempCalls.length === 0 && (activeCalls == null || activeCalls.length === 0))
            setDropdownVisible(false);
        else
            setDropdownVisible(true);
    }

    if (context.user == null) return null;

    const incomingCalls = activeCalls != null && activeCalls.length > 0
        ? _.map(activeCalls, call => {
            return <CallDropdownItem history={navigate} key={call.id} call={call} loadingIgnore={loadingIgnore} loadingIgnoreAll={loadingIgnoreAll} setCallDropdownVisible={setDropdownVisible} ignoreCall={ignoreCall} onTrackCallFinished={onTrackCallFinished} />
        })
        : null;

    const takeElements = loadMoreCalls ? 9999 : activeCalls?.length == 0 ? 2 : 1;

    const notDeletedCalls = _.reject(callsToHandle ?? [], x => x.deleted);

    const shownElements = _.take(notDeletedCalls, takeElements);
    const shown = shownElements.length;
    const remaining = notDeletedCalls.length - shown;

    const callsToHandleViews = _.map(shownElements, call => {
        return <CallDropdownItem history={navigate} key={call.id + call.status} call={call} loadingIgnore={loadingIgnore} loadingIgnoreAll={ loadingIgnoreAll } setCallDropdownVisible={setDropdownVisible} ignoreCall={ignoreCall} onTrackCallFinished={onTrackCallFinished} />;
    });

    const displayIncomingCall = context.user.actorId != ActorIds.System && incomingCalls != null;
    const displayCallsToHandle = context.user.actorId != ActorIds.System;

    return (
        <Dropdown
            overlay={
                <Card className="dropdown-card call-dropdown-card" size="small">
                    <div className="dropdown-links">
                        <div className="links-container">
                            <Link to="/calls" onClick={() => setDropdownVisible(false)}>Calls overview</Link>
                            {context.user.actorId != ActorIds.System ? <Link to={`/calls?contactId=${context.user.actorId}`} onClick={() => setDropdownVisible(false)} style={{ marginLeft: 30 }}>My calls</Link> : null}
                        </div>
                        <Button icon={<CloseOutlined className="close-icon" />} onClick={() => setDropdownVisible(false)} />
                    </div>

                    {displayIncomingCall ?
                        <div className="calls-list">
                            {incomingCalls}
                        </div> : null}

                    {context.user.actorId == ActorIds.System ? null : <Divider className="call-divider" />}

                    {displayCallsToHandle
                        ? callsToHandleViews != null && callsToHandleViews.length == 0
                            ? <div className="no-handle-text">No calls to handle</div>
                            :
                            <div>
                                <div className="calls-list">
                                    {callsToHandleViews}
                                </div>

                                <div className="call-actions">
                                    {remaining > 0
                                        ? <Button className="more-calls-btn" type="link" onClick={(e) => { e.stopPropagation(); setLoadMoreCalls(true) }}>{"+ " + remaining + ' more calls'}</Button>
                                        : shown > 2 ? <Button className="more-calls-btn" type="link" onClick={(e) => { e.stopPropagation(); setLoadMoreCalls(false) }}>Show less calls</Button> : null
                                    }
                                    {notDeletedCalls.length > 0 ? <Button loading={loadingIgnore.length == callsToHandle.length || loadingIgnoreAll} disabled={loadingIgnore.length == callsToHandle.length || loadingIgnoreAll} className="ignore-all-btn" type="link" onClick={(e) => ignoreAllCalls(e)}>Hide all</Button> : null}
                                </div>
                            </div> : null}
                </Card>
            }
            placement="bottomRight"
            arrow
            trigger={['click']}
            onOpenChange={(flag => setDropdownVisible(flag))}
            open={visible}
        >
            <Badge size="small" className="call-badge" count={callsToHandle?.length ?? 0} offset={[0, 5]}>
                <PhoneOutlined />
            </Badge>
        </Dropdown>
    );
}
