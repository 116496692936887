import _ from 'lodash';
import { ActorType, ContactPointLinkView, OrganizationAccessLevel, OrganizationRoleView, TagLinkView, WebAuthenticationInfo } from '../ApiClient/swagger/data-contracts';
import { Profile } from '../Models/_profile';

export class UserProfile implements Profile {
    actorId: string;
    name: string;
    capabilities: string[];
    companies: Record<string, OrganizationAccessLevel>;
    organizationRoles: OrganizationRoleView[];
    contactPoints: ContactPointLinkView[];
    roleIds: string[];
    handlerCompanies: string[];
    isEmployee: boolean;
    employeeId: string;
    isAuthenticated: boolean;
    avatar?: boolean;
    manageAccountUrl?: string;
    tags?: TagLinkView[];
    actorType?: ActorType;

    constructor(data: WebAuthenticationInfo) {
        if (data?.userInfo == null) return;

        const profile = data.userInfo;

        this.actorId = profile.actorId;
        this.name = profile.name;
        this.capabilities = profile.capabilities;
        this.handlerCompanies = profile.handlerCompanies;
        this.contactPoints = profile.contactPoints;
        this.organizationRoles = profile.organizationRoles;
        this.roleIds = profile.roleIds;
        this.isEmployee = profile.isEmployee;
        this.avatar = profile.hasAvatar;
        this.employeeId = profile.id;
        this.isAuthenticated = data.isAuthenticated;
        this.manageAccountUrl = profile.accountUrl;
        this.tags = profile.tags;
        this.actorType = profile.actorType;

        const companies: Record<string, OrganizationAccessLevel> = {};
        _.each(profile.organizationRoles ?? [], or => {
            companies[or.organization.id] = or.role;
        });

        this.companies = companies;
    }

    hasCapability = (capability: string) => {
        const hasCapability = _.find(this.capabilities ?? [], cap => {
            return capability === cap;
        }) != null;

        return hasCapability;
    }

    hasCapabilities = (capabilities: string[]) => {
        let hasAllCapabilities = true;

        _.each(capabilities ?? [], capability => {
            if (!this.hasCapability(capability)) {
                hasAllCapabilities = false;
            }
        });

        return hasAllCapabilities;
    }

    hasAnyCapability = (capabilities: string[]) => {
        let hasAnyCapabilities = false;

        _.each(capabilities ?? [], capability => {
            if (this.hasCapability(capability)) {
                hasAnyCapabilities = true;
            }
        });

        return hasAnyCapabilities;
    }
}

export default UserProfile;