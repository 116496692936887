import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { UpOutlined, DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Form, Radio, RadioChangeEvent, InputNumber, Space, Button, Checkbox, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CreateRoutine, OrganizationSortOption, PersonSortOption, ResourceSortOptions, RoutineView, TaggableType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { ActorIds } from "../../Definitions/_definitions";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import { Drawer } from "../Shared/Drawer";
import RoutineCategorySelector from "./RoutineCategorySelector";
import { PersonSelector } from "../People";
import { Capabilities } from "../../Definitions/_capabilties";
import { OrganizationSelector } from "../Organizations";
import { ResourceSelector } from "../Resources";
import { TagSelector } from "../Tags";
import RoutineSelector from "./RoutineSelector";
import RoutinePeriodSelector from "./RoutinePeriodSelector";
import SeveritySelector from "./SeveritySelector";
import CheckboxlistSelector from "../Shared/CheckboxlistSelector";
import { CustomFormLabel } from "../Shared/CustomFormLabel";

interface CreateRoutineProps {
    routine?: RoutineView;
    onComplete: (created: RoutineView) => void;
    onCancel?: () => void;
    routineRequest?: Partial<CreateRoutine>;
}

interface CreateRoutineState {
    loading: boolean;
    error: string;
    displayMoreInfo: boolean;
    periodical: boolean;
    allChecked: boolean;
    showReuseDrawer: boolean;
    reuseRoutine: boolean;
    routineToReuse: RoutineView;
    responsible: string[]
    checklist: string[];
    affectNextChecked: boolean;
}

class RoutineCreateForm extends React.Component<CreateRoutineProps, CreateRoutineState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            displayMoreInfo: this.props.routineRequest?.organizationId != null || this.props.routineRequest?.resourceId != null,
            periodical: null,
            allChecked: false,
            showReuseDrawer: false,
            reuseRoutine: false,
            routineToReuse: null,
            responsible: this.props.routine ? _.map(this.props.routine?.responsible ?? [], res => { return res.id }) : [],
            checklist: this.props.routine?.checklist ? _.map(this.props.routine?.checklist ?? [], c => c.description) : [],
            affectNextChecked: null
        }
    }

    componentDidMount = () => {
        if (this.state.responsible?.length < 1 && this.context.user.actorId != ActorIds.System)
            this.setState({ responsible: [this.context.user.actorId] });
    }

    onSubmit = async (request: CreateRoutine) => {
        this.setState({ loading: true });

        if (!this.state.periodical || (request.frequency && (request.frequency.interval == null || request.frequency.interval == 0 || request.frequency.type == null))) {
            request.frequency = null;
        } else {
            request.frequency.basedOnExecution = this.state.affectNextChecked == true ? true : false;
        }

        if (this.state.allChecked) {
            request.responsibleIds = null;
            request.allEmployees = true;
        }

        const response = await client.routines.createRoutine(request).catch(exception => this.setState({ error: exception.error.title }));
        if (response) this.props.onComplete(response.data);

        this.setState({ loading: false });
    }

    toggleMoreInfo = () => {
        this.setState({ displayMoreInfo: !this.state.displayMoreInfo });
    }

    onRoutinePeriodicallyChange = (event: RadioChangeEvent) => {
        this.setState({ periodical: event.target.value });
    }

    onAllCheck = (event: CheckboxChangeEvent) => {
        this.setState({ allChecked: event.target.checked });
    }

    onAffectNextCheck = (event: RadioChangeEvent) => {
        this.setState({ affectNextChecked: event.target.value });
    }

    toggleReuseRoutine = () => {
        this.setState({ showReuseDrawer: !this.state.showReuseDrawer });
    }

    onRoutineChange = (routine: RoutineView) => {
        this.setState({
            routineToReuse: routine,
            reuseRoutine: false
        });
    }

    onRoutineReuseComplete = () => {
        this.setState((prevState) => ({
            reuseRoutine: true,
            showReuseDrawer: false,
            periodical: prevState.routineToReuse?.frequency != null,
            routineToReuse: {
                ...prevState.routineToReuse,
                frequency: { interval: 1 },
            },
        }));
    }

    onClear = () => {
        this.setState({
            periodical: null,
            allChecked: false,
            reuseRoutine: false,
            routineToReuse: null
        })
    }

    onResponsibleChange = (data) => {
        this.setState({
            responsible: data
        });
    }

    disabledStartDates = (date: moment.Moment) => {
        const tenYearsAgo = moment().subtract(10, "years").startOf('day');
        if (date < tenYearsAgo) return true;

        return false;
    }

    render = () => {
        const { user } = this.context;

        const routine = this.props.routine
            ? this.props.routine
            : this.state.reuseRoutine
                ? this.state.routineToReuse
                : null;

        const presetRoutineRequest = routine ? {
            title: routine?.title,
            description: routine?.description,
            checklist: routine?.checklist ? _.map(routine?.checklist ?? [], c => c.description) : this.state.checklist,
            categoryId: routine?.category?.id,
            ownerId: this.context.user.actorId != ActorIds.System ? this.context.user.actorId : null,
            responsibleIds: this.state.responsible?.length > 0 ? this.state.responsible : [],
            allEmployees: routine?.allEmployees ?? null,
            frequency: routine?.frequency ? {
                interval: routine.frequency.interval,
                type: routine.frequency.type,
                basedOnExecution: routine.frequency.basedOnExecution,
                startDate: null
            } : this.state.periodical ? {
                interval: 1
            } : null,
            severity: routine?.severity,
            organizationId: routine?.organization?.id,
            resourceId: routine?.resource?.id,
            tagIds: routine ? _.map(routine.tags ?? [], tag => {
                if (tag.category?.deleted || tag?.deleted) return;
                return tag.id; 
            }) : null
        } as Partial<CreateRoutine> : null;

        const routineRequest = Object.assign({}, { ownerId: this.context.user.actorId != ActorIds.System ? this.context.user.actorId : null }, presetRoutineRequest ?? {}, this.props.routineRequest ?? {});

        return (
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={() => this.props.onComplete(null)}
                loading={this.state.loading}
                error={this.state.error}
                //onClear={this.onClear}
                initialValues={routineRequest}
                className="routine-create-form"
            >
                <TextInput
                    title="Title"
                    titleLink={this.props.routine ? null :
                        <Button size="small" onClick={this.toggleReuseRoutine}>Reuse routine</Button>
                    }
                    param="title"
                    placeholder="Title"
                    required
                    warningMessage="Title is required"
                    formItemClassName="routine-title-input"
                />

                <TextAreaInput
                    title="Description"
                    param="description"
                    placeholder="Description"
                    rows={4}
                />

                <SelectorInput
                    param="checklist"
                    title="Checklist"
                    selector={
                        <CheckboxlistSelector />
                    }
                />

                <SelectorInput
                    param="categoryId"
                    title="Type"
                    required
                    selector={
                        <RoutineCategorySelector
                            placeholder="Select category"
                        />
                    }
                />

                <SelectorInput
                    param="ownerId"
                    title="Owner"
                    required
                    selector={
                        <PersonSelector
                            filters={{
                                isEmployee: true,
                                sortBy: PersonSortOption.Name
                            }}
                            disabled={!user.hasCapability(Capabilities.RoutinesWrite)}
                            placeholder="Select employee"
                        />
                    }
                />

                <SelectorInput
                    param="responsibleIds"
                    title="Responsible"
                    titleLink={<div><Checkbox onChange={this.onAllCheck} checked={this.state.allChecked} className="all-employees-form">All employees</Checkbox></div>}
                    className="responsibles-input"
                    selector={
                        <PersonSelector
                            filters={{
                                isEmployee: true,
                                sortBy: PersonSortOption.Name
                            }}
                            placeholder="Select responsible employees"
                            disabled={!user.hasCapability(Capabilities.RoutinesWriteRelated) || this.state.allChecked}
                            onValueChange={this.onResponsibleChange}
                            multiple
                        />
                    }
                />

                <div className="routine-periodical-container">
                    <div className="ant-form-item-label add-more-info-header custom-routine-form-label">
                        <label>Should this routine be executed periodically?</label>
                    </div>
                    <Radio.Group onChange={this.onRoutinePeriodicallyChange} value={this.state.periodical}>
                        <Space direction="vertical">
                            <div className="routine-radio-yes-container">
                                <Radio value={true}>
                                    <span className="yes-text">Yes, every</span>
                                </Radio>
                                <div className="routine-radio-yes">
                                    <span className="routine-period-container">
                                        <Form.Item name={["frequency", "interval"]} className="interval-input" rules={
                                            [{
                                                required: this.state.periodical,
                                                message: 'This field is required'
                                            }]}
                                        >
                                            <InputNumber min={1} disabled={!this.state.periodical} placeholder="0" />
                                        </Form.Item>

                                        <Form.Item name={["frequency", "type"]} className="period-input" required={this.state.periodical} rules={
                                            [{
                                                required: this.state.periodical,
                                                message: 'This field is required'
                                            }]}
                                        >
                                            <RoutinePeriodSelector disabled={!this.state.periodical} />
                                        </Form.Item>
                                    </span>
                                </div>
                            </div>
                            <Radio value={false}>No</Radio>
                            <Radio value="" disabled>Triggered by sensor</Radio>
                            {this.state.periodical
                                ? <div>
                                    <Form.Item label={<div>Start:</div>} name={["frequency", "startDate"]} className="start-date-input" rules={
                                        [{
                                            required: this.state.periodical,
                                            message: 'This field is required'
                                        }]} >
                                        <DateSelector disabledDate={this.disabledStartDates} />
                                    </Form.Item>

                                    <Form.Item
                                        label={
                                            <div>
                                                If the execution of the routine is expedited or delayed, should the next execution date be adjusted accordingly?
                                                <Tooltip className="help-text" title="If so the system will ensure there will be a period between the executions."><InfoCircleOutlined /></Tooltip>
                                            </div>
                                        }
                                    >
                                        <Radio.Group onChange={this.onAffectNextCheck} value={this.state.affectNextChecked} className="radio-input">
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                : null
                            }
                        </Space>
                    </Radio.Group>
                </div>



                <div className={`ant-form-item-label add-more-routine-info-header custom-routine-form-label ${this.state.displayMoreInfo ? "header-open" : "header-closed"}`} onClick={this.toggleMoreInfo}>
                    <div className="more-info-label">
                        <label>Additional info (optional)

                        </label>
                        <div className="more-info-icon-container">
                            {this.state.displayMoreInfo ? <UpOutlined /> : <DownOutlined />}
                        </div>
                    </div>
                </div>

                <div className="more-info-content" style={!this.state.displayMoreInfo ? { display: 'none' } : {}}>
                    <SelectorInput
                        param="severity"
                        title="Severity"
                        selector={<SeveritySelector excludeAllOption />}
                    />

                    <SelectorInput
                        param="organizationId"
                        title="Organization"
                        selector={
                            <OrganizationSelector
                                filters={{
                                    sortBy: OrganizationSortOption.Name
                                }}
                                placeholder="Select organization"
                            />
                        }
                    />

                    <SelectorInput
                        param="resourceId"
                        title="Resource"
                        selector={
                            <ResourceSelector
                                filters={{
                                    sortBy: ResourceSortOptions.Name
                                }}
                                disabled={this.props.routineRequest?.resourceId ? true : false}
                                placeholder="Select resource"
                            />
                        }
                    />

                    <SelectorInput
                        param="tagIds"
                        title="Tags"
                        selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Routine] }} />}
                    />
                </div>


                <Drawer
                    title="Reuse routine"
                    onClose={this.toggleReuseRoutine}
                    open={this.state.showReuseDrawer}
                    destroyOnClose
                    component={
                        <div className="reuse-routine-container">
                            <RoutineSelector
                                title="Routine"
                                onObjectChange={this.onRoutineChange}
                            />

                            <div>
                                <Button onClick={this.onRoutineReuseComplete} type="primary">Done</Button>
                                <Button onClick={this.toggleReuseRoutine}>Cancel</Button>
                            </div>
                        </div>
                    }
                />

            </BaseForm>
        );
    }
}

export default RoutineCreateForm;