import _ from 'lodash';
import moment from 'moment';


const validateDate = (date) => { //Validates date STRICT
    if (moment(date, "YYYY-MM-DDTHH:mm:ss", true).isValid())
        return true;

    if (moment(date, "YYYY-MM-DDTHH:mm", true).isValid())
        return true;

    if (moment(date, "YYYY-MM-DDTHH", true).isValid())
        return true;

    if (moment(date, "YYYY-MM-DD", true).isValid())
        return true;

    if (moment(date, "YYYY-MM", true).isValid())
        return true;

    if (moment(date, "YYYY", true).isValid())
        return true;

    return false;
}

const addParamsToUrl = async (query: any, includeFrom?: boolean) => {
    const url = new URL(window.location.href);
    const updatedQuery = {};

    const subRoute = url.search.includes("subRoute");
    if (subRoute) {
        const subRouteValue = url.search.split("subRoute=").pop();
        const formattedValue = subRouteValue && subRouteValue.includes("&") ? subRouteValue.substring(0, subRouteValue.indexOf("&")) : subRouteValue;

        if (subRouteValue)
            updatedQuery["subRoute"] = formattedValue;
    }

    //TODO: Fix bug with numbers becoming a date
    _.each(query, (q, key) => {
        if (q != null && (!includeFrom && key != 'from' || includeFrom) && key != 'limit') {
            const isValidDate = typeof q == "number" ? false : moment(q).isValid() ? validateDate(q) : false;

            if (isValidDate && key !== "terms")
                updatedQuery[key] = moment(q).format('YYYY-MM-DDTHH-mm-ss');
            else
                updatedQuery[key] = q;
        }
    });

    let updatedUrl = new URL(url.origin + url.pathname);
    Object.keys(updatedQuery).forEach(key => {
        updatedUrl.searchParams.set(key, updatedQuery[key]);
    });

    if (updatedUrl.href[updatedUrl.href.length - 1] == "?")
        updatedUrl = new URL(updatedUrl.href.slice(0, -1));

    window.history.replaceState("", "", updatedUrl.href);
}

const addParamsToQuery = async (query: any, parameters: any, addToUrl?: boolean, includeFrom?: boolean) => {

    _.each(parameters, (value, key) => {
        if (value == null || value == undefined || value.length == 0)
            delete query[key];
        else
            query[key] = value;
    });

    const strippedQuery = _.omitBy(Object.assign({}, query), q => q == null);

    if (addToUrl)
        includeFrom ? await addParamsToUrl(strippedQuery, includeFrom) : await addParamsToUrl(strippedQuery);

    return strippedQuery;
}

const handleQueryParamsOnLoad = async () => {
    const url = new URL(window.location.href);
    const urlParameters = url.search.charAt(0) === '?' ? url.search.substring(1).split('&') : url.search.split('&');
    const query = {
        from: 0,
        limit: 40,
        deleted: false
    };

    let defaultQuery = true;

    await _.each(urlParameters, param => {
        const pair = param.split('=');
        const key = pair[0];
        let value = pair[1] as string;

        if (key === 'terms') {
            value = decodeURI(value);
            value = value.split("+").join(" ").split("%2B").join(" ");
        }
        if (value == null) return;
        else if (key === 'subRoute') {
            query[key] = value;
        }
        else {
            const isValidDate = moment(value, "YYYY-MM-DDTHH-mm-ss", true).isValid();
            let arrValue: string[] | undefined;
            if (typeof value == "string" && value.includes("%2C"))
                arrValue = value.split("%2C");

            query[key] = isValidDate ? moment(value, "YYYY-MM-DDTHH-mm-ss").format('YYYY-MM-DDTHH:mm:ss') : arrValue ? arrValue : value === "true" ? true : value === "false" ? false : value;
            defaultQuery = false;
        }
    });

    return {
        query: query,
        default: defaultQuery
    };
}

const getUrlParamValue = (urlParam: string) => {
    const url = new URL(window.location.href);
    const urlParameters = url && url.search && url.search.charAt(0) === '?' ? url.search.substring(1).split('&') : url.search.split('&');

    let value = null;

    _.each(urlParameters ?? [], p => {
        const pair = p.split('=');
        const key = pair[0];
        if (key == urlParam)
            value = pair[1] as string;
    });

    return value;
}

export {
    handleQueryParamsOnLoad,
    addParamsToQuery,
    addParamsToUrl,
    validateDate,
    getUrlParamValue
};