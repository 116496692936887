import { lazy } from "react";
import { IAppConfig, IAppMenuGroup, IAppMenuItem, IAppRoute, IAppSettings } from "../Models/MenuAndRouteConfig";
import { Capabilities } from "../Definitions/_capabilties";
import { MenuGroupIds } from "../Definitions/_definitions";

import {
    AiOutlineDashboard, AiOutlineCalendar, AiOutlineRead, AiOutlineBank, AiOutlineContacts, AiOutlineProject,
    AiOutlineGroup, AiOutlineClockCircle, AiOutlinePhone, AiOutlineGold, AiOutlineIssuesClose, AiOutlineTeam,
    AiOutlineSchedule, AiOutlineCarryOut, AiOutlineDeliveredProcedure, AiOutlineTool, AiOutlineTable, AiOutlineAim,
    AiOutlineFundProjectionScreen, AiOutlineApi, AiOutlineUsergroupAdd, AiOutlineLogin, AiOutlineLink, AiOutlineBarChart,
    AiOutlineSolution, AiOutlineShop, AiOutlineDeploymentUnit, AiOutlineTags, AiOutlineBook
} from "react-icons/ai";
import { MdSensors } from "react-icons/md";
import { ConfigProviderProps } from "antd/lib/config-provider";
import { AuditOutlined, CheckCircleOutlined } from "@ant-design/icons";


const appRoutes: IAppRoute[] = [
    {
        id: "dashboard",
        path: "/",
        element: lazy(() => import("../Pages/DashboardPage")),
    },
    {
        id: "dashboards",
        path: "/dashboards",
        element: lazy(() => import("../Pages/DashboardListPage")),
    },
    {
        id: "dashboardCreate",
        path: "/dashboards/create",
        element: lazy(() => import("../Pages/DashboardCreatePage")),
    },
    {
        id: "dashboardEdit",
        path: "/dashboards/:dashboardId/edit",
        element: lazy(() => import("../Pages/DashboardEditPage")),
    },

    {
        id: "applications",
        path: "/applications",
        element: lazy(() => import("../Pages/ApplicationListPage")),
    },
    {
        id: "application",
        path: "/applications/:applicationId",
        element: lazy(() => import("../Pages/ApplicationViewPage")),
    },

    {
        id: "roles",
        path: "/roles",
        element: lazy(() => import("../Pages/RoleListPage")),
    },
    {
        id: "rolesCreate",
        path: "/roles/create",
        element: lazy(() => import("../Pages/RoleCreatePage")),
    },
    {
        id: "role",
        path: "/roles/:roleId",
        element: lazy(() => import("../Pages/RoleViewPage")),
    },
    {
        id: "logins",
        path: "/logins",
        element: lazy(() => import("../Pages/LoginListPage")),
    },

    {
        id: "files",
        path: "/files",
        element: lazy(() => import("../Pages/FileListPage")),
    },
    {
        id: "folder",
        path: "/documents/:folderId?",
        element: lazy(() => import("../Pages/FolderAndFileRootViewPage")),
    },
    {
        id: "organizations",
        path: "/organizations",
        element: lazy(() => import("../Pages/OrganizationListPage")),
    },
    {
        id: "organization",
        path: "/organizations/:organizationId",
        element: lazy(() => import("../Pages/OrganizationViewPage")),
    },

    {
        id: "people",
        path: "/people",
        element: lazy(() => import("../Pages/PeopleListPage")),
    },
    {
        id: "person",
        path: "/people/:personId",
        element: lazy(() => import("../Pages/PersonViewPage")),
    },
    {
        id: "actor",
        path: "/actors/:actorId",
        element: lazy(() => import("../Modules/Actors/ActorRedirect")),
    },
    {
        id: "products",
        path: "/products",
        element: lazy(() => import("../Pages/ProductListPage")),
    },
    {
        id: "product",
        path: "/products/:productId",
        element: lazy(() => import("../Pages/ProductViewPage")),
    },
    {
        id: "productCategories",
        path: "/productcategories",
        element: lazy(() => import("../Pages/ProductCategoriesListPage")),
    },

    {
        id: "employees",
        path: "/employees",
        element: lazy(() => import("../Pages/EmployeeListPage")),
    },
    {
        id: "employeeReport",
        path: "/employeereport/:employeeId?",
        element: lazy(() => import("../Pages/EmployeeReportPage")),
    },

    {
        id: "tags",
        path: "/tags",
        element: lazy(() => import("../Pages/TagListPage")),
    },

    {
        id: "tickets",
        path: "/tickets",
        element: lazy(() => import("../Pages/TicketListPage")),
    },
    {
        id: "ticket",
        path: "/tickets/:ticketId",
        element: lazy(() => import("../Pages/TicketViewPage")),
    },

    {
        id: "contactPoints",
        path: "/contactpoints",
        element: lazy(() => import("../Pages/ContactPointListPage")),
    },

    {
        id: "timetracking",
        path: "/timetracking",
        element: lazy(() => import("../Pages/HourCategoryListPage")),
    },

    {
        id: "meetings",
        path: "/meetings",
        element: lazy(() => import("../Pages/MeetingListPage")),
    },

    {
        id: "measuringPoints",
        path: "/measuringPoints",
        element: lazy(() => import("../Pages/MeasuringPointListPage"))
    },

    {
        id: "projects",
        path: "/projects",
        element: lazy(() => import("../Pages/ProjectListPage")),
    },
    {
        id: "project",
        path: "/projects/:projectId",
        element: lazy(() => import("../Pages/ProjectViewPage")),
    },
    {
        id: "projectReport",
        path: "/projectreport/:projectId?",
        element: lazy(() => import("../Pages/ProjectReportPage")),
    },

    {
        id: "tasks",
        path: "/tasks",
        element: lazy(() => import("../Pages/TaskListPage")),
    },
    {
        id: "task",
        path: "/tasks/:taskId",
        element: lazy(() => import("../Pages/TaskViewPage")),
    },

    {
        id: "calendar",
        path: "/calendar",
        element: lazy(() => import("../Pages/CalendarPage")),
    },
    {
        id: "hours",
        path: "/hours",
        element: lazy(() => import("../Pages/ActivityListPage")),
    },
    {
        id: "confirmactivities",
        path: "/hours/confirm",
        element: lazy(() => import("../Pages/ConfirmActivitiesPage")),
    },
    {
        id: "activitytransfers",
        path: "/hours/transfer",
        element: lazy(() => import("../Pages/ActivityTransferListPage")),
    },

    {
        id: "orders",
        path: "/orders",
        element: lazy(() => import("../Pages/OrderListPage")),
    },
    {
        id: "order",
        path: "/orders/:orderId",
        element: lazy(() => import("../Pages/OrderViewPage")),
    },
    {
        id: "orderCategories",
        path: "/ordercategories",
        element: lazy(() => import("../Pages/OrderCategoryListPage")),
    },

    {
        id: "leads",
        path: "/leads",
        element: lazy(() => import("../Pages/LeadListPage")),
    },
    {
        id: "lead",
        path: "/leads/:leadId",
        element: lazy(() => import("../Pages/LeadViewPage")),
    },
    {
        id: "leadCauses",
        path: "/leadcauses",
        element: lazy(() => import("../Pages/LeadCausesListPage")),
    },

    {
        id: "invoices",
        path: "/invoices",
        element: lazy(() => import("../Pages/InvoiceListPage")),
    },

    {
        id: "revenue",
        path: "/revenue",
        element: lazy(() => import("../Pages/RevenueReportPage")),
    },
    {
        id: "subscriptionreport",
        path: "/subscriptionreport",
        element: lazy(() => import("../Pages/SubscriptionReportPage")),
    },
    {
        id: "orderreport",
        path: "/orderreport",
        element: lazy(() => import("../Pages/OrderReportPage")),
    },
    {
        id: "incidents",
        path: "/incidents",
        element: lazy(() => import("../Pages/IncidentListPage")),
    },
    {
        id: "incident",
        path: "/incidents/:incidentId",
        element: lazy(() => import("../Pages/IncidentViewPage")),
    },
    {
        id: "incidentcategories",
        path: "/incidentcategories",
        element: lazy(() => import("../Pages/IncidentCategoriesListPage")),
    },

    {
        id: "resources",
        path: "/resources",
        element: lazy(() => import("../Pages/ResourceListPage")),
    },
    {
        id: "resource",
        path: "/resources/:resourceId",
        element: lazy(() => import("../Pages/ResourceViewPage")),
    },
    {
        id: "resourceCategories",
        path: "/resourcecategories",
        element: lazy(() => import("../Pages/ResourceSetupPage")),
    },

    {
        id: "subscriptions",
        path: "/subscriptions",
        element: lazy(() => import("../Pages/SubscriptionListPage")),
    },
    {
        id: "subscription",
        path: "/subscriptions/:subscriptionId",
        element: lazy(() => import("../Pages/SubscriptionViewPage")),
    },
    {
        id: "subscriptiontransfers",
        path: "/subscriptiontransfers",
        element: lazy(() => import("../Pages/SubscriptionTransferListPage")),
    },

    {
        id: "routines",
        path: "/routines",
        element: lazy(() => import("../Pages/RoutineListPage")),
    },
    {
        id: "routine",
        path: "/routines/:routineId",
        element: lazy(() => import("../Pages/RoutineViewPage")),
    },
    {
        id: "routineCategories",
        path: "/routinecategories",
        element: lazy(() => import("../Pages/RoutineCategoryListPage")),
    },

    {
        id: "channels",
        path: "/channels",
        element: lazy(() => import("../Pages/ChannelListPage")),
    },

    {
        id: "calls",
        path: "/calls",
        element: lazy(() => import("../Pages/CallListPage")),
    },
    {
        id: "call",
        path: "/calls/:callId",
        element: lazy(() => import("../Pages/CallViewPage")),
    },

    {
        id: "externallinks",
        path: "/externallinks",
        element: lazy(() => import("../Pages/ExternalLinkManagementPage")),
    },

    {
        id: "news",
        path: "/news",
        element: lazy(() => import("../Pages/NewsFeedPage")),
    },
    {
        id: "news",
        path: "/news/items/:newsId",
        element: lazy(() => import("../Pages/NewsViewPage")),
    },
    {
        id: "newsManagement",
        path: "/news/management",
        element: lazy(() => import("../Pages/FeedManagementPage")),
    },
    {
        id: "newscreatepage",
        path: "/news/items/create",
        element: lazy(() => import("../Pages/NewsCreatePage")),
    },
    {
        id: "newseditpage",
        path: "/news/items/:newsId/edit",
        element: lazy(() => import("../Pages/NewsEditPage")),
    },

    {
        id: "supplierCategories",
        path: "/suppliercategories",
        element: lazy(() => import("../Pages/SupplierCategoryListPage")),
    },

    {
        id: "customerCategories",
        path: "/customercategories",
        element: lazy(() => import("../Pages/CustomerCategoryListPage")),
    },
    {
        id: "polls",
        path: "/polls",
        element: lazy(() => import("../Pages/PollListPage")),
    },
    {
        id: "poll",
        path: "/polls/:pollId",
        element: lazy(() => import("../Pages/PollViewPage")),
    },
    {
        id: "measuringPointTemplateViewPage",
        path: "/measuringpointtemplates/:templateId",
        element: lazy(() => import("../Pages/MeasuringPointTemplateViewPage"))
    },
    {
        id: "sensors",
        path: "/sensors",
        element: lazy(() => import("../Pages/SensorListPage"))
    },
    {
        id: "sensor",
        path: "/sensors/:sensorId",
        element: lazy(() => import("../Pages/SensorViewPage"))
    },
    {

        id: "salarytransfers",
        path: "/salaries",
        element: lazy(() => import("../Modules/Salaries/SalaryBasisTransferListPage"))
    },
    {
        id: "salaryTransferPage",
        path: "/salaries/:salaryId",
        element: lazy(() => import("../Modules/Salaries/SalaryBasisTransferViewPage"))
    },
    {
        id: "salaryEmployeePage",
        path: "/employees/:employeeId/salary",
        element: lazy(() => import("../Modules/Salaries/SalaryEmployeeViewPage"))
    },
    {
        id: "salaryEmployeeListPage",
        path: "/employees/salaryall",
        element: lazy(() => import("../Modules/Salaries/SalaryEmployeeListViewPage"))
    },
    {
        id: "approveabsence",
        path: "/approveabsence",
        element: lazy(() => import("../Pages/ApproveAbsencePage"))
    },
    {
        id: "vacationreport",
        path: "/vacationreport",
        element: lazy(() => import("../Pages/VacationReportPage"))
    },
    {
        id: "selfcertificationreport",
        path: "/selfcertificationreport",
        element: lazy(() => import("../Pages/SelfCertificationReportPage"))
    },
    {
        id: "illnesschildreport",
        path: "/illnesschildreport",
        element: lazy(() => import("../Pages/IllnessChildReportPage"))
    },
    {
        id: "hourbank",
        path: "/hourbank",
        element: lazy(() => import("../Pages/HourBankPage"))
    },
    {
        id: "reports",
        path: "/reports",
        element: lazy(() => import("../Modules/ReportBuilder/ReportListPage"))
    },
    {
        id: "createreport",
        path: "/reports/create",
        element: lazy(() => import("../Modules/ReportBuilder/CreateReportPage"))
    },
    {
        id: "editreport",
        path: "/reports/update/:reportId",
        element: lazy(() => import("../Modules/ReportBuilder/EditReportPage"))
    },
    {
        id: "reportpreview",
        path: "/reports/preview/:reportId",
        element: lazy(() => import("../Modules/ReportBuilder/ReportPreview"))
    }
];

const appMenus: (IAppMenuGroup | IAppMenuItem)[] = [
    {
        id: MenuGroupIds.Overview,
        title: "My overview",
        items: [
            {
                id: "dashboard",
                title: "Dashboard",
                route: "/",
                icon: <AiOutlineDashboard />,
                requiredCapability: Capabilities.MenuDashboard,
            },
            {
                id: "calendar",
                title: "Calendar",
                route: "/calendar",
                icon: <AiOutlineCalendar />,
                requiredCapability: Capabilities.MenuCalendar,
            },
            {
                id: "news",
                title: "News",
                route: "/news",
                icon: <AiOutlineRead />,
                requiredCapability: Capabilities.MenuNews,
            },
        ],
    },
    {
        id: MenuGroupIds.Network,
        title: "Network",
        items: [
            {
                id: "organizations",
                title: "Organizations",
                route: "/organizations",
                icon: <AiOutlineBank />,
                requiredCapability: Capabilities.MenuOrganizations,
            },
            {
                id: "people",
                title: "People",
                route: "/people",
                icon: <AiOutlineContacts />,
                requiredCapability: Capabilities.MenuPeople,
            },
        ],
    },
    {
        id: MenuGroupIds.Management,
        title: "Management",
        items: [
            {
                id: "projects",
                title: "Projects",
                route: "/projects",
                icon: <AiOutlineProject />,
                requiredCapability: Capabilities.MenuProjects
            },
            {
                id: "tickets",
                title: "Tickets",
                route: "/tickets",
                icon: <AiOutlineGroup />,
                requiredCapability: Capabilities.MenuTickets
            },
            {
                id: "hours",
                title: "Hours",
                route: "/hours",
                icon: <AiOutlineClockCircle />,
                requiredCapability: Capabilities.MenuHours
            },
            {
                id: "calls",
                title: "Calls",
                route: "/calls",
                icon: <AiOutlinePhone />,
                requiredCapability: Capabilities.MenuCalls
            },
            {
                id: "incidents",
                title: "Incidents",
                route: "/incidents",
                icon: <AiOutlineIssuesClose />,
                requiredCapability: Capabilities.MenuIncidents
            },
            {
                id: "employees",
                title: "Employees",
                route: "/employees",
                icon: <AiOutlineTeam />,
                requiredCapability: Capabilities.MenuEmployees
            },
            {
                id: "meetings",
                title: "Meetings",
                route: "/meetings",
                icon: <AiOutlineSchedule />,
                requiredCapability: Capabilities.MenuMeetings
            },
            {
                id: "routines",
                title: "Routines",
                route: "/routines",
                icon: <AiOutlineCarryOut />,
                requiredCapability: Capabilities.MenuRoutines
            },
            {
                id: "salaries",
                title: "Salary hours",
                route: "/salaries",
                icon: <AuditOutlined />,
                requiredCapability: Capabilities.EmployeesAdministrateSalaryBasis
            },
            {
                id: "approveabsence",
                title: "Approve absence",
                route: "/approveabsence",
                icon: <CheckCircleOutlined />,
                requiredCapability: Capabilities.MenuApproveAbsence,
            }
        ],
    },
    {
        id: MenuGroupIds.Sales,
        title: "Sales",
        items: [
            {
                id: "orders",
                title: "Orders",
                route: "/orders",
                icon: <AiOutlineDeliveredProcedure />,
                requiredCapability: Capabilities.MenuOrders,
            },
            {
                id: "products",
                title: "Products",
                route: "/products",
                icon: <AiOutlineTool />,
                requiredCapability: Capabilities.MenuProducts,
            },
            {
                id: "subscriptions",
                title: "Subscriptions",
                route: "/subscriptions",
                icon: <AiOutlineTable />,
                requiredCapability: Capabilities.MenuSubscriptions,
            },
            {
                id: "leads",
                title: "Leads",
                route: "/leads",
                icon: <AiOutlineAim />,
                requiredCapability: Capabilities.MenuLeads,
            },
            {
                id: "invoices",
                title: "Invoices",
                route: "/invoices",
                icon: <AiOutlineFundProjectionScreen />,
                requiredCapability: Capabilities.MenuInvoices,
            },
        ],
    },
    {
        id: MenuGroupIds.Documents,
        title: "Documents",
        route: "/documents",
        requiredCapability: Capabilities.MenuSharedFolders,
    },
    {
        id: MenuGroupIds.Sensors,
        title: "Monitoring",
        items: [
            {
                id: "resources",
                title: "Resources",
                route: "/resources",
                icon: <AiOutlineGold />,
                requiredCapability: Capabilities.MenuResources,
            },
            {
                id: "sensors",
                title: "Sensors",
                route: "/sensors",
                icon: <MdSensors />,
                requiredCapability: Capabilities.MenuSensors
            }
        ]
    },
    {
        id: MenuGroupIds.Reports,
        title: "Reports",
        items: [
            {
                id: "reports",
                title: "Report builder",
                route: "/reports",
                requiredCapability: Capabilities.MenuReportBuilder
            },
            {
                id: "projectreport",
                title: "Project report",
                route: "/projectreport",
                requiredCapability: Capabilities.MenuProjectReport,
            },
            {
                id: "employeereport",
                title: "Employee report",
                route: "/employeereport",
                requiredCapability: Capabilities.MenuEmployeeReport,
            },
            {
                id: "subscriptionreport",
                title: "Subscription report",
                route: "/subscriptionreport",
                requiredCapability: Capabilities.MenuSubscriptionReport,
            },
            {
                id: "revenue",
                title: "Revenue report",
                route: "/revenue",
                requiredCapability: Capabilities.MenuRevenueReport,
            },
            {
                id: "orderreport",
                title: "Order transfer report",
                route: "/orderreport",
                requiredCapability: Capabilities.MenuOrderReport,
            },
            {
                id: "vacationreport",
                title: "Vacation report",
                route: "/vacationreport",
                requiredCapability: Capabilities.MenuVacationReport,
            },
            {
                id: "selfcertificationreport",
                title: "Self-certification report",
                route: "/selfcertificationreport",
                requiredCapability: Capabilities.MenuSelfCertificationReport,
            },
            {
                id: "illnesschildreport",
                title: "Illness child report",
                route: "/illnesschildreport",
                requiredCapability: Capabilities.MenuIllnessChildReport,
            },
            {
                id: "hourbank",
                title: "Hour bank",
                route: "/hourbank",
                requiredCapability: Capabilities.MenuHourBank,
            }
        ],
    },
    { id: MenuGroupIds.Links, title: "External links", items: [] },
    {
        id: MenuGroupIds.Configuration,
        title: "Configuration",
        items: [
            {
                id: MenuGroupIds.Access,
                title: "Access",
                items: [
                    {
                        id: "applications",
                        title: "Applications",
                        route: "/applications",
                        icon: <AiOutlineApi />,
                        requiredCapability: Capabilities.MenuApplications,
                    },
                    {
                        id: "roles",
                        title: "Roles",
                        route: "/roles",
                        icon: <AiOutlineUsergroupAdd />,
                        requiredCapability: Capabilities.MenuRoles,
                    },
                    {
                        id: "logins",
                        title: "Logins",
                        route: "/logins",
                        icon: <AiOutlineLogin />,
                        requiredCapability: Capabilities.MenuExternalLogins,
                    },
                ],
            },
            {
                id: MenuGroupIds.Interface,
                title: "Interface",
                items: [
                    {
                        id: "externallinks",
                        title: "External links",
                        route: "/externallinks",
                        icon: <AiOutlineLink />,
                        requiredCapability: Capabilities.MenuConfigExternalLinks,
                    },
                    {
                        id: "dashboards",
                        title: "Dashboard setup",
                        route: "/dashboards",
                        icon: <AiOutlineDashboard />,
                        requiredCapability: Capabilities.MenuDashboardSetup,
                    },
                    {
                        id: "polls",
                        title: "Polls",
                        route: "/polls",
                        icon: <AiOutlineBarChart />,
                        requiredCapability: Capabilities.MenuPolls,
                    },
                ],
            },
            {
                id: MenuGroupIds.Categories,
                title: "Categories",
                items: [
                    {
                        id: "timetracking",
                        title: "Time tracking",
                        route: "/timetracking",
                        icon: <AiOutlineClockCircle />,
                        requiredCapability: Capabilities.MenuTimeTracking,
                    },
                    {
                        id: "resourcecategories",
                        title: "Resources",
                        route: "/resourcecategories",
                        icon: <AiOutlineGold />,
                        requiredCapability: Capabilities.MenuResourceCategories,
                    },
                    {
                        id: "incidentcategories",
                        title: "Incidents",
                        route: "/incidentcategories",
                        icon: <AiOutlineIssuesClose />,
                        requiredCapability: Capabilities.MenuIncidentCategories,
                    },
                    {
                        id: "routinecategories",
                        title: "Routines",
                        route: "/routinecategories",
                        icon: <AiOutlineCarryOut />,
                        requiredCapability: Capabilities.MenuRoutineCategories,
                    },
                    {
                        id: "ordercategories",
                        title: "Orders",
                        route: "/ordercategories",
                        icon: <AiOutlineDeliveredProcedure />,
                        requiredCapability: Capabilities.MenuOrdersCategories,
                    },
                    {
                        id: "productcategories",
                        title: "Products",
                        route: "/productcategories",
                        icon: <AiOutlineTool />,
                        requiredCapability: Capabilities.MenuProductsCategories,
                    },
                    {
                        id: "leadcauses",
                        title: "Leads",
                        route: "/leadcauses",
                        icon: <AiOutlineAim />,
                        requiredCapability: Capabilities.MenuLeadCategories,
                    },
                    {
                        id: "customercategories",
                        title: "Customers",
                        route: "/customercategories",
                        icon: <AiOutlineSolution />,
                        requiredCapability: Capabilities.MenuCustomerCategories,
                    },
                    {
                        id: "suppliercategories",
                        title: "Suppliers",
                        route: "/suppliercategories",
                        icon: <AiOutlineShop />,
                        requiredCapability: Capabilities.MenuSupplierCategories,
                    },
                ],
            },
            {
                id: "channels",
                title: "Channels",
                route: "/channels",
                icon: <AiOutlineDeploymentUnit />,
                requiredCapability: Capabilities.MenuChannels,
            },
            {
                id: "tags",
                title: "Tags",
                route: "/tags",
                icon: <AiOutlineTags />,
                requiredCapability: Capabilities.MenuTags,
            },
            {
                id: "contactpoints",
                title: "Contact info",
                route: "/contactpoints",
                icon: <AiOutlineBook />,
                requiredCapability: Capabilities.MenuContactPoints,
            },
        ],
    },
];


const appSettings: IAppSettings = {
    mobileWidth: 1100
}


class AppConfiguration implements IAppConfig {
    routes: IAppRoute[];
    menus: (IAppMenuGroup | IAppMenuItem)[];
    settings: IAppSettings;
    antdOptions?: ConfigProviderProps;

    constructor() {
        this.routes = appRoutes;
        this.menus = appMenus;
        this.settings = appSettings;
        this.antdOptions = this.setAntdConfigProvider();
    }

    private setAntdConfigProvider() {
        const antdConfigProvider: ConfigProviderProps = {
            theme: {
                token: {
                    colorPrimary: '#3589B6',
                    colorLink: '#3589B6'
                }
            }
        };

        return antdConfigProvider;
    }
}

export default AppConfiguration;